import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { Stack, alpha } from '@mui/system';
import { CustomAvatar } from '../../custom-avatar';
import { onGetFullname } from '../../../utils/general';
import AppointmenOverviewButton from '../appointments/AppointmenOverviewButton';

const PatientDetailCard = ({
    user
}) => {

    const displayName = onGetFullname(user)    

    return (  
        <Box sx={{
            //border:`1px solid ${blueGrey[100]}`,
            borderRadius:1,
            px:2, py:2,
            background: theme => alpha(theme.palette.primary.main, 0.2)
        }}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Grid container wrap='nowrap' spacing={2}>
                            <Grid item>
                                <CustomAvatar src={user?.image} alt={displayName} name={displayName} />
                            </Grid>
                            <Grid item xs>
                                <Stack>
                                    <Stack direction='row'  spacing={1}>
                                        <Typography variant='subtitle1'>
                                            {displayName}
                                        </Typography>
                                        <Stack spacing={1} direction='row' sx={{
                                            fontFamily: theme => theme.typography.fontFamily
                                        }}>
                                            {user?.client && <Chip label={user?.client} color='primary' size='small' />}
                                            {user?.client_location && <Chip label={user?.client_location} color='secondary' size='small' />}
                                            {user?.client_program && <Chip label={user?.client_program} color='primary' size='small' />}
                                        </Stack>
                                    </Stack>
                                    
                                    <Typography variant='body2' color='text.secondary'>
                                        {user?.email}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <AppointmenOverviewButton />
                    </Grid>
                    
                </Grid>
        </Box>
    );
}
 
export default PatientDetailCard;