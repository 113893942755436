import { private_server } from '../../utils/axios';

const useTag = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/tag/all', {params:params})
            result.data = request.data.data?.tags??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(idtag) => {
            let result = null
            const request = await private_server.get(`/tag/${idtag}`)
            result = request.data.data.tag
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/tag', data2send)
            result = request.data.data?.tag?.id_tag
            return result
        },
        onEdit: async(idtag, data2send) => {
            await private_server.patch(`/tag/${idtag}`, data2send)
        },
        onDelete: async(id) => {
            await private_server.delete(`/tag/${id}`)
        },
    }

    return {
        actions
    }
}
 
export default useTag;