import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useForm from '../../../hooks/useForm';
import useUserPolicy from '../../../hooks/api/useUserPolicy';
import { catalogs } from '../../../utils/catalogs';
import InputSelectForm from '../../../components/forms/InputSelectForm';

const CONTENT = {
    add:{
        title:'Agregar póliza',
        description:'Ingrese los datos del documento.',
        button:'Crear'
    },
    edit:{
        title:'Edición de póliza',
        description:'En esta sección puede visualizar y modificar los datos de la poliza',
        button:'Actualizar'
    }
}

const EmployeePolicyModal = (props) => {

    const {open, onClose, onRefresh, type, user_id, origin} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const formManager = useForm(form_data)
    const userPolicyManger = useUserPolicy()
    const {form, onValidateForm, onGetFormData, onResetForm, onInitForm} = formManager

    const content = CONTENT[type]

    useEffect(() => {
        if(open) onInitModule()
    },[open])

    const onInitModule = async() => {
        try {
            if(type === 'add'){
                onResetForm({})
            }else{
                onInitForm(origin)
            }
            setError(null)
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeForm = (data) => {
        setError('')
        formManager.onChangeForm(data)
    }

    const onSubmit = async() => {
        //console.log(form)
        const valid = onValidateForm(form)
            
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        let data2send

        if(type === 'add'){
            data2send = onGetFormData({removeEmptySpaces:true})
            data2send.user_id = user_id
        }else{
            data2send = onGetFormData({eraseEmptySpaces:true})
        }

        


        setSending(true)

        try {
            if(type === 'add'){
                console.log('Send data', data2send)
                const id = await userPolicyManger.actions.onAdd(data2send)

                console.log(id)
            }else{
                await userPolicyManger.actions.onEdit(origin?.id_user_policy, data2send)
            }
            
            await onRefresh()

        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    

    return ( 
        <BaseModal maxWidth='sm'
        title={content?.title}
        open={open} 
        onClose={onClose} >
            <div>
                <Typography variant='body1' className={classes.description}>
                    {content?.description}
                </Typography>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.name} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelectForm data={form.path} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.start_date} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.end_date} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.details} onChange={onChangeForm} />
                        </Grid>
                        
                        
                        <Grid item xs={12} sx={{mt:4}}>
                            <Grid container spacing={2}>
                                {error && 
                                    <Grid item xs={12}>
                                        <ErrorAlert 
                                        error={error} 
                                        onClose={() => setError(null)} />
                                    </Grid> 
                                }
                                <Grid item xs={12}>
                                        <ContainedButton  loading={sending} onClick={onSubmit} fullWidth>
                                            {content?.button}
                                        </ContainedButton>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default EmployeePolicyModal;

const form_data = {
    name: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Nombre del documento',
            placeholder:'Nombre del documento',
            helperText:'Debe de ser un valor válido',
            InputLabelProps:{ shrink: true }
        },
        rules: {type: 'distance', min:1}
    },
    path: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.policies_foder_paths],
        config: {
            id: 'path',
            type: 'select',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Carpeta',
            placeholder:'Seleccione una carpeta',
            helperText:'Debe de ser un valor',
            InputLabelProps:{ shrink: true }
        },
        rules: {type: 'select'}
    },
    start_date: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'start_date',
            type: 'date',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Fecha de inicio',
            placeholder:'Fecha de inicio',
            helperText:'Debe de ser una fecha valida',
            InputLabelProps:{ shrink: true }
        },
        rules: {type: 'date'}
    },
    end_date: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'end_date',
            type: 'date',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Fecha de fin',
            placeholder:'Fecha de fin',
            helperText:'Debe de ser una fecha valida',
            InputLabelProps:{ shrink: true }
        },
        rules: {type: 'date'}
    },
    details: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Comentarios',
            placeholder:'Comentarios',
            helperText:'Debe de ser un valor válido',
        },
        rules: {type: 'none'}
    },
    

}