import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { USER_TYPES } from '../../utils/enums';
import { fileTypeByUrl } from '../../utils/files';
import { onGetUserTypeFromToken } from '../../utils/general';


const useClient = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            let request
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                request = await private_server.get('/client/all', {params:params})
                result.data = request.data.data?.clients??[]
                result.count = request.data.data.count
            }else{
                request = await private_server.get('/nutritionistclient/all/nutritionist/me', {params:params})
                result.data = request.data.data?.nutritionist_clients??[]
                result.count = request.data.data.count
            }
            return result
        },
        onGet: async(idclient) => {
            let result = null
            const request = await private_server.get(`/client/${idclient}`)
            result = request.data.data.client
            return result
        },
        onGetMe: async() => {
            let result = null
            const request = await private_server.get(`/client/me`)
            result = request.data.data.client
            return result
        },
        
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/client', data2send)
            result = request.data.data?.client?.id_client
            return result
        },
        onEdit: async(idclient, data2send) => {
            await private_server.patch(`/client/${idclient}`, data2send)
        },
        onEnable: async(idclient) => {
            await private_server.post(`/client/${idclient}/enable`)
        },
        onDisable: async(idclient) => {
            await private_server.post(`/client/${idclient}/disable`)
        },
        onDelete: async(id) => {
            await private_server.delete(`/client/${id}`)
        },
        onUpdatePicture: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {name:'image',ext:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/client/${id}/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.url, file, options);
        },
        onGetBlobFile: async(url, name) => {
            const config = { responseType: 'blob' };
            console.log(`${CONFIG_SETTINGS.S3_SERVER_URL}${url}`)
            const response = await generic_server.get(`${CONFIG_SETTINGS.S3_SERVER_URL}${url}`, config);
            return new File([response.data], name??'image-velfare', { type: response.data.type })
        },

    }

    return {
        actions
    }
}
 
export default useClient;