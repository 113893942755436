
const { pisa_server } = require("../../utils/axios")

const PisaManager = () => {

    const onGetSimulator = async(data2send) => {
        const request = await pisa_server.post("/simulator", data2send)
        console.log(request.data)
         //await sleep(1000)
        if(request.data.busqueda && request.data.busqueda.length) {
            console.log(request.data.busqueda)
            return request.data.busqueda[0]
        }else{
            return null
        }
    }

    const onGetRFC_CURP = async(data) => {
        const request = await pisa_server.post("/CURP", data)
        if(request.data.busqueda && request.data.busqueda.length) {
            const aux = request.data.busqueda[0]
            console.log(aux)
            return {
                curp:aux['(expression 2)'],
                rfc:aux['(expression 1)']
            }
        }else{
            return null
        }
    }

    const onGetGendersCat = async() => {
        const request = await pisa_server.post("/CATALOGO", {idcat:"6"})
        if(request.data.busqueda && request.data.busqueda.length) {
            return request.data.busqueda
        }else{
            return []
        }
    }

    const onGetStatesCat = async() => {
        const request = await pisa_server.post("/CATALOGO", {idcat:"16"})
        if(request.data.busqueda && request.data.busqueda.length) {
            return request.data.busqueda
        }else{
            return []
        }
    }

    const onGetZipCodeInfo = async(zipcode) => {
        const request = await pisa_server.post("/CP", {codigopostal:zipcode})
        if(request.data.busqueda && request.data.busqueda.length) {
            return request.data.busqueda
        }else{
            return []
        }
    }

    return {
        onGetSimulator,
        onGetGendersCat,
        onGetStatesCat,
        onGetRFC_CURP,
        onGetZipCodeInfo
    }
}

export default PisaManager