import { Box, Card, Table, TableContainer, TableBody, Grid, Button } from '@mui/material';
import React from 'react'
import Iconify from '../../../../../components/iconify';
import TableEmptyRows from '../../../../../components/table/TableEmptyRows';
import TableHeadCustom from '../../../../../components/table/TableHeadCustom';
import TableNoData from '../../../../../components/table/TableNoData';
import TablePaginationCustom from '../../../../../components/table/TablePaginationCustom';
//import PharmacyPaymentModal from '../../../../../modals/Pharmacies/PharmacyPaymentModal/PharmacyPaymentModal';
import { emptyRows } from '../../../../../utils/tables';
import UserAddressCard from './UserAddressCard';
import useUserAddressesSection from './useUserAddressesSection';
import LoadingContainer from '../../../../../components/loaders/LoadingContainer';
import EmployeePolicyModal from '../../../../../modals/Employees/EmployeePolicyModal/EmployeePolicyModal';
import { useParams } from 'react-router';
import PolicyFileModal from '../../../../../modals/Employees/PolicyFileModal/PolicyFileModal';
import EmployeeAddressModal from '../../../../../modals/Employees/EmployeeAddressModal/EmployeeAddressModal';
//import PharmacyPaymentFileModal from '../../../../../modals/Pharmacies/PharmacyPaymentFileModal/PharmacyPaymentFileModal';

const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'name', label: 'Nombre', align: 'left' },
    { id: 'start_date', label: 'Fecha', align: 'left' },

    { id: 'end_date', label: 'Total', align: 'left' },
    { id: 'document', label: 'Documento', align: 'left' },
    //{ id: 'status', label: 'Status', align: 'left' },
    { id: '', label: '', align: '' },
];
  

const UserAddressesSection = (props) => {

    const {
        loading,
        addresses, 
        count, 
        selected,
        fileType,
        onChangePagination,
        tableController,
        modalManager,
        onSelectRow,
        onEditRow,
        onDeleteRow,
        onRequestAddressses,
        onUploadFile,
        onGetFile,
    } = useUserAddressesSection(props)
    const {iduser} = useParams()


    const {pagination, filter} = tableController
    const {modals, onChangeModalStatus} = modalManager

    const page = pagination.offset
    const rowsPerPage = pagination.limit

    const dense = true
    const denseHeight = dense ? 52 : 72;
    const isNotFound = !addresses.length

    console.log(modals)

    return ( 
        <Box>
            <PolicyFileModal
                origin={selected}
                open={modals.upload_file} 
                onRefresh={onRequestAddressses}
                onClose={() => onChangeModalStatus('upload_file', false)} 
            />
            <EmployeeAddressModal
                type='edit'
                origin={selected}
                user_id={iduser}
                open={modals.edit_policy} 
                onRefresh={onRequestAddressses}
                onClose={() => onChangeModalStatus('edit_policy', false)} 
            />
            <EmployeeAddressModal
                type='add'
                user_id={iduser}
                open={modals.add_user} 
                onRefresh={onRequestAddressses}
                onClose={() => onChangeModalStatus('add_user', false)} 
            />
            <Grid container sx={{mb:2}} justifyContent='flex-end' >
                <Grid item>
                    <Button
                        variant='contained' 
                        onClick={() => onChangeModalStatus('add_user', true)} 
                        startIcon={<Iconify icon='eva:plus-fill'/>}
                    >
                        Agregar dirección
                    </Button>
                </Grid>
            </Grid>
            <LoadingContainer loading={false}>
                <Box>
                    <Grid container spacing={3}>
                        {addresses.map((address) => (
                            <Grid item xs={12} md={6}>
                                <UserAddressCard
                                    key={`address-${address?.id_address}`}
                                    address={address}
                                    onDeleteRow={() => onDeleteRow(address)}
                                    onEditRow={() => onEditRow(address)}
                                    onCompleted={onRequestAddressses}
                                />
                            </Grid>
                            
                        ))}
                        
                    </Grid>
                    
                </Box>
            
            </LoadingContainer>
            
        </Box>
     );
}
 
export default UserAddressesSection;