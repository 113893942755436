//import { Button } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
import PageLayout from '../../../layouts/PageLayout';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { onGetFullImagePath } from '../../../utils/general';
import AccountPasswordSection from './sections/AccountPasswordSection';
import GeneralAccountSection from './sections/GeneralAccountSection';
import useBusinessEmployeePage from './useBusinessEmployeePage';
import PatientDetailCard from '../../../components/platform/patients/PatientDetailCard';
import UserPoliciesSection from './sections/UserPoliciesSection/UserPoliciesSection';
import UserAddressesSection from './sections/UserAddressesSection/UserAddressesSection';

const BusinessEmployeePage = (props) => {
    
    
    const {system, data, actions, form, passwordForm} = useBusinessEmployeePage()

    const {currentTab, file, account, active, locations, programs} = data
    const profileImg =  onGetFullImagePath(account?.image)
    const TABS = [
      {
        value: 'general',
        label: 'General',
        icon: <Iconify icon="ic:round-account-box" />,
        component: (
          <GeneralAccountSection active={active} account={account}
              file={file || profileImg} form={form} loading={system.sending} 
              locations={locations} programs={programs}
              onAvatarDrop={actions.onAvatarDrop}
              onInputChange={actions.onInputChange}
              onSubmit={actions.onSubmit}
              onChangeUserStatus={actions.onChangeUserStatus}
              onAutosave={actions.onAutosave}
          />
        ),
      },
      {
        value: 'policies',
        label: 'Pólizas',
        icon: <Iconify icon="ic:round-vpn-key" />,
        component: <UserPoliciesSection />,
      },
      {
        value: 'addresses',
        label: 'Direcciones',
        icon: <Iconify icon="iconamoon:home" />,
        component: <UserAddressesSection />,
      },
      {
        value: 'change_password',
        label: 'Seguridad',
        icon: <Iconify icon="ic:round-vpn-key" />,
        component: <AccountPasswordSection onReset={actions.onReset}
          form={passwordForm} loading={system.sending} 
          onInputChange={actions.onPasswordInputChange}
          onSubmit={actions.onUpdatePassword}
        />,
      },
    ];

    return ( 
        <PageLayout stretch={true}
            enable_return 
            title='Detalles del empleado'
            title_seo='Detalles del empleado | Velfare Finance'
            loading={system.loading}
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Clientes', href:PATH_DASHBOARD.general.patients},
                {name:'Cuenta', },
            ]}
        >
            <div>
                <Box sx={{
                  pb:2
                }}>
                  <PatientDetailCard user={account}/>
                </Box>
                <NavigationTab options={TABS} value={currentTab} onChange={actions.onChangeSection}/>
                
                {TABS.map(
                (tab) =>
                    tab.value === currentTab && (
                        <Box key={tab.value} sx={{ mt: 5 }}>
                            {tab.component}
                        </Box>
                    )
                )}
            </div>
        </PageLayout>
     );
}
 
export default BusinessEmployeePage;