import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactSVG } from 'react-svg'

const SVGIcon = ({src, size, color, width, height, style}) => {

    const classes = useStyles()

    const _size = 32
    const icon_size = size ? size : _size

    return ( 
        <div style={{width: width ? width : icon_size, height:  height ? height : icon_size, ...style}}>
            <div className={classes.root}>
                <ReactSVG 
                    src={src}
                    height={height ? height : icon_size}
                    width={width ? width : icon_size}
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `fill: ${color ? color : '#000'}; width:${width ? width : icon_size}; height:${height ? height : icon_size}`)
                    }}
                />
            </div>
        </div>
        
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        height:'100%',
    }   
}))
 
export default SVGIcon;