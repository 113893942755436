import { Box, Card, Table, TableContainer, TableBody, Grid, Button } from '@mui/material';
import React from 'react'
import Iconify from '../../../../../components/iconify';
import TableEmptyRows from '../../../../../components/table/TableEmptyRows';
import TableHeadCustom from '../../../../../components/table/TableHeadCustom';
import TableNoData from '../../../../../components/table/TableNoData';
import TablePaginationCustom from '../../../../../components/table/TablePaginationCustom';
//import PharmacyPaymentModal from '../../../../../modals/Pharmacies/PharmacyPaymentModal/PharmacyPaymentModal';
import { emptyRows } from '../../../../../utils/tables';
import UserPolicyRow from './UserPolicyRow';
import useUserPoliciesSection from './useUserPoliciesSection';
import LoadingContainer from '../../../../../components/loaders/LoadingContainer';
import EmployeePolicyModal from '../../../../../modals/Employees/EmployeePolicyModal/EmployeePolicyModal';
import { useParams } from 'react-router';
import PolicyFileModal from '../../../../../modals/Employees/PolicyFileModal/PolicyFileModal';
//import PharmacyPaymentFileModal from '../../../../../modals/Pharmacies/PharmacyPaymentFileModal/PharmacyPaymentFileModal';

const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'name', label: 'Nombre', align: 'left' },
    { id: 'folder', label: 'Folder', align: 'left' },
    { id: 'start_date', label: 'F. inicio', align: 'left' },

    { id: 'end_date', label: 'F. fin', align: 'left' },
    { id: 'document', label: 'Documento', align: 'left' },
    //{ id: 'status', label: 'Status', align: 'left' },
    { id: '', label: '', align: '' },
];
  

const UserPoliciesSection = (props) => {

    const {
        loading,
        policies, 
        count, 
        selected,
        fileType,
        onChangePagination,
        tableController,
        modalManager,
        onSelectRow,
        onEditRow,
        onDeleteRow,
        onRequestPolicies,
        onUploadFile,
        onGetFile,
    } = useUserPoliciesSection(props)
    const {iduser} = useParams()


    const {pagination, filter} = tableController
    const {modals, onChangeModalStatus} = modalManager

    const page = pagination.offset
    const rowsPerPage = pagination.limit

    const dense = true
    const denseHeight = dense ? 52 : 72;
    const isNotFound = !policies.length

    console.log(modals)

    return ( 
        <Box>
            {/*<PharmacyPaymentFileModal
                origin={selected}
                type={fileType}
                open={modals.upload_file} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('upload_file', false)} 
            />
            <PharmacyPaymentModal
                type='add'
                pharmacy_id={props?.pharmacy?.id_pharmacy}
                open={modals.add_user} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('add_user', false)} 
            />
            <PharmacyPaymentModal
                type='edit'
                origin={selected}
                pharmacy_id={props?.pharmacy?.id_pharmacy}
                open={modals.edit_policy} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('edit_policy', false)} 
    />*/}
            <PolicyFileModal
                origin={selected}
                open={modals.upload_file} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('upload_file', false)} 
            />
            <EmployeePolicyModal
                type='edit'
                origin={selected}
                user_id={iduser}
                open={modals.edit_policy} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('edit_policy', false)} 
            />
            <EmployeePolicyModal
                type='add'
                user_id={iduser}
                open={modals.add_user} 
                onRefresh={onRequestPolicies}
                onClose={() => onChangeModalStatus('add_user', false)} 
            />
            <Grid container sx={{mb:2}} justifyContent='flex-end' >
                <Grid item>
                    <Button
                        variant='contained' 
                        onClick={() => onChangeModalStatus('add_user', true)} 
                        startIcon={<Iconify icon='eva:plus-fill'/>}
                    >
                        Agregar poliza
                    </Button>
                </Grid>
            </Grid>
            <LoadingContainer loading={false}>
                <Card sx={{overflow:'auto'}}>
                    <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                        
                            <Table stickyHeader size={'small'} sx={{ minWidth: 800}}>
                                <TableHeadCustom
                                    order={pagination.order}
                                    orderBy={pagination.order_by}
                                    headLabel={TABLE_HEAD}
                                    rowCount={count}
                                    numSelected={0}
                                    //onSort={actions.onSort}
                                />

                                <TableBody>
                                    {policies.map((row) => (
                                        <UserPolicyRow
                                            key={`pharmauser-item-${row?.id_user_policy}`}
                                            row={row}
                                        //selected={selected.includes(row.id)}
                                            //onSelectRow={() => onSelectRow(row)}
                                            onDeleteRow={() => onDeleteRow(row)}
                                            onEditRow={() => onEditRow(row)}
                                            onUploadFile={onUploadFile}
                                            onGetFile={onGetFile}
                                            onCompleted={onRequestPolicies}
                                        />
                                    ))}
                                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, policies.length)} />
                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            </Table>
                    </TableContainer>

                    
                </Card>
                <Box sx={{marginTop:2}}>
                    <TablePaginationCustom      
                        tableFilter={pagination}               
                        total={count}
                        onChangePagination={onChangePagination}
                    />
                </Box>
            </LoadingContainer>
            
        </Box>
     );
}
 
export default UserPoliciesSection;