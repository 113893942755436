import React from 'react'
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import { blueGrey } from '@mui/material/colors';
import { onGetDateWithTimezone } from '../../../utils/formatTime';
import { generateArrayOfArrays } from '../../../utils/general';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      flexWrap:'wrap',
    },
    description: {
      width: "60%",
    },
    xyz: {
      width: "40%",
    },
});


const OverviewPDF = ({
    fullname, 
    appointments=[],
    metrics=[]
}) => {

    let _splittedappointments =  generateArrayOfArrays(appointments, 6)
    let maindata = []

    for(let i=0; i<_splittedappointments.length; i++){
        maindata.push({
            appointments: _splittedappointments[i],
            metrics: metrics,
        })
    }

    return ( 
        <Document
            
        >
            <Page 
                wrap
                orientation="landscape"
                style={{
                    padding:16
                }}>
                <View style={{
                    backgroundColor: "#ED841B",
                    padding:8,
                    borderRadius:8,
                    color:"#FFF",
                    marginBottom:16,
                    position:'relative'
                }}>
                    <Text style={{fontSize:10}}>Paciente</Text>
                    <Text style={{fontSize:11, fontWeight:"bold"}}>{fullname}</Text>
                    <View style={{
                        position:'absolute',
                        top:4, right:4,
                        backgroundColor:"#FFF",
                        borderRadius:4, padding:2
                    }}>
                        <Image 
                            style={{
                                width:60,
                            }}
                            src="/assets/logo-text.png"
                        />
                    </View>
                    
                </View>
                {maindata.map((item, key) => {
                    return(
                        <View key={`maincontet-${key}`} style={{marginTop:16}} wrap={false}>
                            <AppointmentOverviewContent
                                appointments={item.appointments}
                                metrics={item.metrics}
                            />
                        </View>
                    )
                })}
                
                
            </Page>
            
            
        </Document>
     );
}
 
export default OverviewPDF;

const AppointmentOverviewContent = ({
    appointments=[],
    metrics=[]
}) => {
    return(
        <>
            <Header appointments={appointments} />
            <BodyContent 
                appointments={appointments} 
                metrics={metrics} 
            />
        </>
        
    )
}

const BodyContent = ({
    appointments=[],
    metrics=[]
}) => {

    const data2render = metrics.map(item => {
            if(!item.show) return;
            return(
                <View style={styles.row} key={`row-${item.name}`}>
                    <Cell style={{backgroundColor: "#FEE4D3",}}>
                        <Text style={{fontWeight:'bold'}}>
                            {item.name}
                        </Text>
                    </Cell>
                    {appointments.map((el, key) => {
                        let _value = el[item.id]
                        if(item.id === "diet"){
                            _value = el[item.id] || el.nutrify_diet
                        }
                        if(item.id ==="imc"){
                            if(!_value && el?.height && el?.weight){
                                const height = parseFloat(el?.height)/100
                                const weight = parseFloat(el?.weight)
                                _value = parseFloat(weight/(height*height)).toFixed(3)
                            }
                        }
                        return(
                            <Cell key={`rowcell-${item.name}-${key}`}>
                                <Text>
                                    {_value}
                                </Text>
                            </Cell>
                        )
                    })}
                        
                    
                </View>

            )
    })

    return data2render
    
}

const Header = ({
    appointments=[]
}) => {
    return(
        <View style={{...styles.row}}>
            <CellHeader style={{width:100,}}>
                <Text>
                    Fecha de consulta
                </Text>
            </CellHeader>
            {appointments.map(item => {
                return(
                    <CellHeader 
                        //sx={{minWidth:180, background: theme => theme.palette.primary.main, color:'white'}} 
                        key={`rowhead-${item.id_appointment}`}
                    >
                        <Text>
                            {onGetDateWithTimezone(item?.start_date, 'DD/MM/YYYY')}
                        </Text>
                    </CellHeader>
                )
            })}
        </View>
    )
    
}


const Cell = ({children, style}) => {
    return(
        <View style={{
            fontSize:9,
            width:100, 
            minHeight:20,
            height:'100%',
            textAlign:'center', 
            border:`1px solid ${blueGrey[300]}`,
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            ...style}}>
            {children}
        </View>
    )
}

const CellHeader = ({children, style}) => {
    return(
        <View style={{
            fontSize:9,
            width:100, 
            minHeight:20,
            height:'100%',
            textAlign:'center', 
            border:`1px solid #FFF`,
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:"#F0780F",
            color:'white',
            ...style}}>
            {children}
        </View>
    )
}



