import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { fileTypeByUrl } from '../../utils/files';
import FileSaver from 'file-saver';


const useUserPolicy = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/userpolicy/all', {params:params})
            result.data = request.data.data?.user_policys??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(iduserpolicy) => {
            let result = null
            const request = await private_server.get(`/userpolicy/${iduserpolicy}`)
            result = request.data.data.user_policy
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/userpolicy', data2send)
            result = request.data.data?.user_policy?.id_user_policy
            return result
        },
        onEdit: async(iduserpolicy, data2send) => {
            await private_server.patch(`/userpolicy/${iduserpolicy}`, data2send)
        },
        onDelete: async(iduserpolicy) => {
            await private_server.delete(`/userpolicy/${iduserpolicy}`)
        },
        onUpdateFile: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {name:'file',ext:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/userpolicy/${id}/file`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.url, file, options);
        },
        onDownloadFile: async(id, name) => {
            console.log(id, name)
            const request = await private_server.get(`/userpolicy/${id}/file`, {params:{name:"file"}})
            const _url = request.data.data.url
            console.log('URL  path', _url)
           // let _url = onGetFullImagePath(relativepath)
           // if(!_url) return null
           const config = { responseType: 'blob' };
           const response = await generic_server.get(_url, config);
           console.log(response)
        //   const _file =  new File([response.data], name, { type: response.data.type })
//
           FileSaver.saveAs(response.data, name);
        },
        ////////////////////////////////// IMAGES ///////////////////////////////////////
        onGetImages: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/productimage/all', {params:params})
            result.data = request.data.data?.product_images??[]
            result.count = request.data.data.count
            return result
        },
        onAddImage: async(data2send) => {
            const response =  await private_server.post(`/productimage`, data2send)
            return response.data.data.product_image.id_product_image
        },

        onDeleteImage: async(product_image_id) => {
            await private_server.delete(`/productimage/${product_image_id}`)
        },
        onUpdatePicture: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {name:'image',ext:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/productimage/${id}/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.url, file, options);
        },
        ////////////////////////////////// CATEGORIES ///////////////////////////////////////
        onGetCategories: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/productcategory/all', {params:params})
            result.data = request.data.data?.product_categories??[]
            result.count = request.data.data.count
            return result
        },
        onAddCategory: async(data2send) => {
            let result = null
            const request = await private_server.post('/productcategory', data2send)
            result = request.data.data?.product_category?.id_product_category
            return result
        },
        onEditCategory: async(idproductcategory, data2send) => {
            await private_server.patch(`/productcategory/${idproductcategory}`, data2send)
        },
        onDeleteCategory: async(idproductcategory, data2send) => {
            await private_server.delete(`/productcategory/${idproductcategory}`, data2send)

        },
        ////////////////////////////////// TAGS ///////////////////////////////////////
        onGetTags: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/producttag/all', {params:params})
            result.data = request.data.data?.product_tags??[]
            result.count = request.data.data.count
            return result
        },
        onAddTag: async(data2send) => {
            let result = null
            const request = await private_server.post('/producttag', data2send)
            result = request.data.data?.product_tag?.id_product_tag
            return result
        },
        onEditTag: async(idproducttag, data2send) => {
            await private_server.patch(`/producttag/${idproducttag}`, data2send)
        },
        onDeleteTag: async(idproducttag) => {
            await private_server.delete(`/producttag/${idproducttag}`)

        },
    }

    return {
        actions
    }
}
 
export default useUserPolicy;