import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect, useCallback } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useUser from '../../../hooks/api/useUser';
import useForm from '../../../hooks/useForm';
import useUserPolicy from '../../../hooks/api/useUserPolicy';
import AddressForm from '../../../components/forms/AddressForm';
import useUserAddress from '../../../hooks/api/useUserAddress';

const CONTENT = {
    add:{
        title:'Agregar dirección',
        description:'Ingrese los datos de la dirección del usuario.',
        button:'Crear'
    },
    edit:{
        title:'Edición de dirección',
        description:'En esta sección puede visualizar y modificar los datos de la dirección del usuario',
        button:'Actualizar'
    }
}

const EmployeeAddressModal = (props) => {

    const {open, onClose, onRefresh, type, user_id, origin} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [ziploading, setZipLoading] = useState(false)
    const userAddressManger = useUserAddress()
    

    const content = CONTENT[type]

    useEffect(() => {
        if(open) onInitModule()
    },[open])

    const onInitModule = async() => {
        try {

            if(type === 'add'){
                setError(null)
                setLoading(false)
                setSending(false)
            }else{
                //onInitForm(origin)
            }
            setError(null)
        } catch (error) {
            console.log(error)
        }
    }

    /*const onSubmit = async() => {
        //console.log(form)
        const valid = onValidateForm(form)
            
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        let data2send

        if(type === 'add'){
            data2send = onGetFormData({removeEmptySpaces:true})
            data2send.user_id = user_id
        }else{
            data2send = onGetFormData({eraseEmptySpaces:true})
        }

        


        setSending(true)

        try {
            if(type === 'add'){
                console.log('Send data', data2send)
                const id = await userPolicyManger.actions.onAdd(data2send)

                console.log(id)
            }else{
                await userPolicyManger.actions.onEdit(origin?.id_user_policy, data2send)
            }
            
            await onRefresh()

        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }*/

    

    return ( 
        <BaseModal maxWidth='sm'
        title={content?.title}
        open={open} 
        onClose={onClose} >
            <div>
                <Typography variant='body1' className={classes.description}>
                    {content?.description}
                </Typography>
                <div>
                    <AddressForm
                        user_id={user_id}
                        type={type}
                        onRefresh={onRefresh}
                        onClose={onClose}

                    />
                </div>
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default EmployeeAddressModal;

