import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Stack, Button, TableRow, MenuItem, TableCell, IconButton, Typography, alpha, Box, Tooltip, Card, Grid } from '@mui/material';
// components
import Iconify from '../../../../../components/iconify';
import MenuPopover from '../../../../../components/menu-popover';
import ConfirmDialog from '../../../../../components/confirm-dialog';
import ContainedButton from '../../../../../components/actions/ContainedButton';
import { blueGrey } from '@mui/material/colors';

// ----------------------------------------------------------------------

UserAddressRow.propTypes = {
  address: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onUploadFile: PropTypes.func
};

export default function UserAddressRow({ 
  address, 
  selected,
   onEditRow, 
   onUploadFile,
   onDeleteRow,
   }) {

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);


  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <Card sx={{
        p:3,
        position:'relative'
      }}>
        <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover} sx={{
          position:'absolute',
          top:8, right:8
        }}>
            <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
        <Grid container spacing={3}>
          <Grid item>
            <Iconify icon="subway:location-3" color={theme => theme.palette.primary.main} width={48}/>
          </Grid>
          <Grid item xs>
            <Stack>
              <Typography variant='subtitle1'>
                {address?.name}
              </Typography>
              <Typography color='text.secondary' variant='body2'>
                {address?.street} {address?.exterior_number} {address?.interior_number}
              </Typography>
              <Typography color='text.secondary' variant='body2'>
                {address?.suburb}
              </Typography>
              <Typography color='text.secondary' variant='body2'>
                {address?.municipality}
              </Typography>
              <Typography color='text.secondary' variant='body2'>
                {address?.state}, {address?.zip_code}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" >
        

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
          sx={{ color: blueGrey[600] }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar dirección"
        content="¿Está seguro de eliminar esta dirección? Ya no podrá recuperarse en un futuro"
        action={
          <ContainedButton size='small' onClick={() => {
            handleCloseConfirm();
            onDeleteRow()
          }}>
            Eliminar
          </ContainedButton>
        }
      />
    </>
  );
}
