import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import CustomSmallSelect from '../../../../components/custom-input/CustomSmallSelect';
import useClient from '../../../../hooks/api/useClient';
import LoadingContainer from '../../../../components/loaders/LoadingContainer';
import AnalyticsByBusinessTable from '../async_components/AnalyticsByBusinessTable';

const dateRangeOptions = [
    {id:1, label:'2023', start_date:'2023-01-01', end_date:'2023-12-31' },
    {id:2, label:'Último mes', start_date:moment().subtract(1, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
    {id:3, label:'Últimos 3 meses', start_date:moment().subtract(3, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
    {id:4, label:'Últimos 6 meses', start_date:moment().subtract(6, 'month').format('YYYY-MM-DD'), end_date:moment().format('YYYY-MM-DD') },
]

const BusinessAnalyticsSection = () => {

    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const [selected, setSelected] = useState(1)
    const [start_date, setStartDate] = useState('2023-01-01')
    const [end_date, setEndDate] = useState('2023-12-31')
    const [business, setBusiness] = useState()
    const [businesses, setBusinesses] = useState([])

    const clientManager = useClient()

    const onUpdateDates = (e) => {
        const value =  parseInt(e.target.value)
        setSelected(setSelected)
        const findObj = dateRangeOptions.find(el => el.id === value)
        setStartDate(findObj?.start_date)
        setEndDate(findObj?.end_date)
    }

    const initialize = useCallback(async()=> {
        try {
            const _clients = await clientManager.actions.onGetAll({order:0, order_by:JSON.stringify(['name'])})
            //console.log(_clients.data)
            setBusinesses(_clients?.data ?? [])
        } catch (error) {
            console.log(error)
        }
        setDone(true)
    }, [])


    useEffect(() => {
        initialize()
    }, [])

    if(loading || !done) return <LoadingContainer /> 


    return ( 
        <Box>   
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant='subtitle1' sx={{mb:3}}></Typography>
                        </Grid>
                        <Grid item>
                            <CustomSmallSelect value={selected} onChange={onUpdateDates}>
                                {dateRangeOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.label}
                                    </option>
                                ))}
                            </CustomSmallSelect>
                        </Grid>
                    </Grid>
                </Grid>
                <div style={{width:'100%', marginTop:24}}>
                    <Stack spacing={3}>
                        {businesses.map(business => {
                            //console.log(business)
                            return(
                                <AnalyticsByBusinessTable
                                    key={`client-${business?.id_client}`}
                                    client={business}
                                    start_date={start_date}
                                    end_date={end_date}
                                    enable={done}
                                />
                            )
                        })}
                        
                    </Stack>
                </div>
            </Grid>
        </Box>
     );
}
 
export default BusinessAnalyticsSection;