import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useModal from "../../../../../hooks/useModal";
import useTableSettings from "../../../../../hooks/useTableSettings";
import useUserPolicy from "../../../../../hooks/api/useUserPolicy";

const useUserPoliciesSection = (props) => {

    const navigate = useNavigate();
    const {iduser} = useParams()

    const { enqueueSnackbar } = useSnackbar();
    const userPolicyManager = useUserPolicy()


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [pharmacy, setPharmacy] = useState()
    const [policies, setPolicies] = useState([])
    const [count, setCount] = useState(0)
    const [selected, setSelected] = useState()
    const [fileType, setFileType] = useState('')

    const modalManager = useModal({
        add_user:false,
        delete_user:false,
        edit_policy:false,
        upload_file:false
    })

    const tableController = useTableSettings({
        initial_filter:{
            search:{key:'search', value:'', operator:'LIKE'},
        },
        initial_params:{
            order_by:JSON.stringify(['id_user_policy']),
            order:1,
            limit:10, 
            offset:0,
        }
    })

    const onUploadFile = useCallback(async(row, type) => {
        setSelected(row)
        setFileType(type)
        modalManager.onChangeModalStatus('upload_file', true)
    }, [modalManager])

    
    const onGetFile = useCallback(async(row, type) => {
        let filename = type
        console.log(row?.id_user_policy, filename)
        await userPolicyManager.actions.onDownloadFile(row?.id_user_policy, filename)
        //modalManager.onChangeModalStatus('upload_file', true)
    }, [modalManager])

    const onEditRow = useCallback(async(data) => {
        console.log('Edit row', data)
        setSelected(data)
        modalManager.onChangeModalStatus('edit_policy', true)
    }, [modalManager])

    const onChangePassword = useCallback(async(data) => {
        setSelected(data)
        modalManager.onChangeModalStatus('edit_password', true)
    }, [modalManager])

    

    const onDeleteRow = useCallback(async(data) => {
        console.log('Delete row', data)
        if(sending) return
        setSending(true)
        try {
            
            await userPolicyManager.actions.onDelete(data?.id_user_policy)
            await onRequestPolicies()
            enqueueSnackbar('Póliza eliminada existosamente', {variant:'success'})
        } catch (error) {
            enqueueSnackbar(error?.message, {variant:'error'})
        }
        setSending(false)
    }, [userPolicyManager, sending])


    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        result.offset =  result.offset * result.limit;
        console.log('Pharmacy', pharmacy)
        let ANDargs = [{"field":"user_id","operator":"=","value":iduser}]
        result.filter = JSON.stringify({AND:ANDargs})
        

        return result
    },[tableController.pagination, tableController.filter, iduser])

    const onRequestPolicies = useCallback(async() => {
        try {
            const params = onGetParams()
            console.log('Params', params)
            const _payments = await userPolicyManager.actions.onGetAll(params)
            console.log(_payments.data)
            setPolicies(_payments.data)
            setCount(_payments.count)
        } catch (error) {
            
        }
    }, [pharmacy, tableController, iduser])

    useEffect(() => {
        console.log('request policies')
        onRequestPolicies()
    }, [pharmacy, tableController.filter, tableController.pagination])


    const initialize = useCallback(async() => {
        console.log('Inicialziar farmacia policies', iduser)
        setLoading(true)
        try {
            await onRequestPolicies()
        } catch (error) {
            console.log(error)
        }
        

        setLoading(false)
    }, [iduser])

    useEffect(() => {
       initialize()
    }, [iduser])

    return {
        loading,
        policies, 
        count, 
        selected,
        fileType,
        tableController,
        modalManager,
        onRequestPolicies,
        onDeleteRow,
        onEditRow,
        onChangePassword,
        onUploadFile,
        onGetFile,
        onRequestPolicies
    };
}
 
export default useUserPoliciesSection;