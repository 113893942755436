import React from 'react';
import { Fade,  Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ProductName = ({product, settings}) => {

    const classes = useStyles()

    const nameLines = settings?.nameLines ?? null
    const presentationLines = settings?.presentationLines ?? null
    const enableActiveSubstance = settings?.enableActiveSubstance ?? null

    //console.log(product)

    let productname = product?.product || product?.name
    if(product?.sub_name) productname = `${productname} ${product?.sub_name}`

    const _substance = product?.active_substance ?? null

    const _additional_measure = product.additional_measure ?? ''
    const product_quantity = product.product_quantity ?? ''
    const measure = product.measure ?? ''

    let _presentation = '' 
    const _measure = product_quantity > 1 ? `${measure}` : measure
    if(product_quantity) _presentation = `${product_quantity}`
    if(measure) _presentation = `${_presentation} ${_measure.toLowerCase()}`
    if(_additional_measure) _presentation = `${_presentation} de ${_additional_measure}`

    _presentation = _presentation || product?.presentation
    _presentation = product?.presentation ?? ''


    return ( 
        <>
            <Fade in timeout={1000}>
                <div className={classes.content}>
                    <Typography variant='body1' className={classes.title} style={{lineClamp:nameLines}}>
                        {productname}
                    </Typography>
                    {enableActiveSubstance && _substance ? (
                        <div>
                            <Typography variant='body1' className={classes.subtitle} style={{lineClamp:presentationLines}}>
                                {_substance} 
                            </Typography>
                        </div>
                        
                    ) : null}
                    <Typography variant='body2' className={classes.subtitle} style={{lineClamp:presentationLines}}>
                        {_presentation}
                    </Typography>
                </div>
            </Fade>
        
        </>
     );
}

const useStyles = makeStyles(theme => ({
    content:{
        minHeight:70
    },
    title:{
        fontWeight:500,
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 1,
        wordBreak: "break-all",
        overflow: "hidden"
    },
    subtitle:{
        fontWeight:300,
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        wordBreak: "break-all",
        overflow: "hidden"
    },
}))
 
export default ProductName;