import { onGetFullImagePath } from "../utils/general";

export const onGetUserData = (_data, variant) => {

    let result = {}
    const userImage = onGetFullImagePath(_data?.image)

    switch(variant){
        case 'SIDEBAR_AVATAR':
            result = {
                displayName: `${_data?.first_name??''} ${_data?.fathers_last_name??''}`,
                role: _data?.user_type ?? '',
                image:userImage
            }
            break;
        case 'HEADER_AVATAR':
            result = {
                displayName: `${_data?.first_name??''} ${_data?.fathers_last_name??''}`,
                role: _data?.user_type ?? '',
                email: _data?.email,
                image:userImage
            }
            break;
        default:
            result = {..._data, image:userImage}
    }

    return result
}