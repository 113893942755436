import { Box, Button } from '@mui/material';
import React, { useState } from 'react'
import AppointmentsOverviewModal from '../../../modals/Appointments/AppointmentsOverviewModal/AppointmentsOverviewModal';

const AppointmenOverviewButton = () => {

    const [open, setOpen] = useState(false)
    

    return ( 
        <>
            <Box sx={{
                display:'inline-block'
            }}>
                <Button
                    variant='outlined'
                    onClick={() => setOpen(true)}
                    fullWidth={false}
                >
                    Resumen
                </Button>
            </Box>
            <AppointmentsOverviewModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
     );
}
 
export default AppointmenOverviewButton;