import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { fileTypeByUrl } from '../../utils/files';


const useNutritionistClient = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/nutritionistclient/all', {params:params})
            result.data = request.data.data?.nutritionist_clients??[]
            result.count = request.data.data.count
            return result
        },
        onGetAllMe: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/nutritionistclient/all/nutritionist/me', {params:params})
            result.data = request.data.data?.nutritionist_clients??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(id_nutritionist_client) => {
            let result = null
            const request = await private_server.get(`/nutritionistclient/${id_nutritionist_client}`)
            result = request.data.data.nutritionist_client
            return result
        },
        
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/nutritionistclient', data2send)
            result = request.data.data?.nutritionist_client?.id_nutritionist_client
            return result
        },
        onEdit: async(id_nutritionist_client, data2send) => {
            await private_server.patch(`/nutritionistclient/${id_nutritionist_client}`, data2send)
        },
        onDelete: async(id) => {
            await private_server.delete(`/nutritionistclient/${id}`)
        },

    }

    return {
        actions
    }
}
 
export default useNutritionistClient;