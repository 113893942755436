import React, { useRef } from 'react'
import { CircularProgress, TextField } from '@mui/material'
import { ruleValidation } from './customFunctions'
import {withStyles} from '@mui/styles'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const InputForm = props => {

    const {data, onChange, onBlur, startAdornment, endAdornment, onKeyUp, onKeyDown, delayedSearch, onDelayedSearch, loading, inputRootStyles, disabled, onKeyPress} = props
    const {config, value, isValid, isVisited} = data

    const id = data?.config?.id

    const timerRef = useRef()

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.compareValue);
        temp.isVisited = true;
        onChange(temp);
    } 

    
    /*const onInnerBlur = event => {
      const response = event.target.value;
      let temp = {...data};
      temp.value = response;
      temp.isValid = ruleValidation(temp.value, temp.rules, temp.compareValue);
      temp.isVisited = true;
      onBlur(temp);
    } */

    const onStartCountDown = () => {
      if(timerRef.current) clearTimeout(timerRef.current)
      timerRef.current = setTimeout(onRequestSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onRequestSearch = async() => { 

      if(timerRef.current) clearTimeout(timerRef.current) 
      onDelayedSearch(id, value)
        //
        //let temp = {...tableFilter}
        //temp.offset = 0
        //onUpdateTableFilter(temp)            
    }


    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    //console.log(loading)


    return(
        <div>
            <CssTextField  value={value} variant='outlined' disabled={disabled}
            error={isError} {...interConfig} onChange={onInnerChange}  
            
            InputProps={
                {
                  
                  inputProps:{style:{letterSpacing:'0.1em',...inputRootStyles}},
                    startAdornment:startAdornment ? startAdornment : undefined,
                    //endAdornment:endAdornment ? endAdornment : undefined,
                    endAdornment:loading ? <div><CircularProgress size={24}/></div> : (endAdornment ? endAdornment : undefined),
                    onKeyUp: delayedSearch ? (onStartCountDown) : (onKeyUp ? onKeyUp : undefined),
                    onKeyDown: delayedSearch ? onStopCountDown : (onKeyDown ? onKeyDown : undefined),
                    onKeyPress:onKeyPress,
                    onBlur:onBlur

                }}
            />
        </div>
    )
}

export default InputForm