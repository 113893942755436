import React from 'react';
import { alpha, Button, CircularProgress, Fade, Grid, Grow } from '@mui/material';
import cx from 'classnames'
import { grey, red } from '@mui/material/colors';
import {makeStyles} from '@mui/styles'

const btn_paddings = {
    'small': '8px 22px',
    'medium': '12px 28px',
    'large': '16px 34px'
}

const loader_width = {
    'small': 44,
    'medium': 52,
    'large': 60
}

const ContainedButton = ({loading, children, color, onClick, fullWidth, disabled, size, href}) => {

    const classes = useStyles()

    const btn_style = {
        padding: btn_paddings[size??'medium']
    }

    const loader_style = {
        width: loader_width[size??'medium']
    }

    return ( 
        <div>
            {!loading ? <Grow in timeout={500}>
                <Button onClick={!loading ? onClick : undefined} 
                fullWidth={fullWidth} disabled={disabled} href={href}
                
                className={cx({
                    [classes.root]:true, 
                    [classes.root_small]:size==='small',
                    [classes.secondary]: color==='secondary',
                    [classes.red]: color==='red',
                    [classes.white]: color==='white',
                    [classes.grey]: color==='grey',
                    [classes.transparent]: color==='transparent'
                })}
                style={{...btn_style}}
                >
                        {children}
                </Button>
            </Grow> : (
                <Fade in timeout={500}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <div className={cx({
                                [classes.loading_container]:true,
                                [classes.secondary]: color==='secondary',
                                [classes.red]: color==='red',
                                [classes.white]: color==='white',
                                [classes.grey]: color==='grey',
                                [classes.transparent]: color==='transparent'
                            })}
                            style={{...loader_style}}
                            >
                                <div className={classes.progress_container}>
                                    <CircularProgress size={24} className={classes.progress}/>

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Fade>
            )}
            
        </div>
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        fontFamily:theme.typography.fontFamily,
        textTransform:'none',
        padding:'12px 28px',
        borderRadius:32,
        color:'white',
        fontSize:'1rem',
        fontWeight:500,
        boxShadow:theme.customShadows.primary,
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        '&:disabled':{
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    root_small:{
        padding:'8px 20px',
        fontSize:'0.875rem',
    },
    
    primary:{
        background:theme.palette.primary.main,
        boxShadow:theme.customShadows.primary,
        '&:hover':{background:theme.palette.primary.dark}
    },
    secondary:{
        background:theme.palette.secondary.main,
        boxShadow:theme.customShadows.secondary,
        '&:hover':{background:theme.palette.secondary.dark}
    },
    red:{
        background:red[700],
        '&:hover':{background:red[900]}
    },
    grey:{
        background:grey[300],
        '&:hover':{background:grey[400]},
        color:grey[900],
    },
    white:{
        color:grey[900],
        background:'white',
        boxShadow:theme.shadows[10],
        '&:hover':{
            background:alpha('#000',0.025),
            boxShadow:theme.shadows[10]
        }
    },
    loading_container:{
        background:theme.palette.primary.main,
        boxShadow:theme.customShadows.primary,
        borderRadius:'50%',
        boxSizing:'border-box',
        position:'relative',
        paddingTop:'100%'
    },
    progress_container:{
        position:'absolute',
        marginTop:2,
        top:'50%',left:'50%',
        //width:50,
        //height:50,
        transform:'translate(-50%, -50%)'
    },
    progress:{
        color:'white',
        
    },
    
}))
 
export default ContainedButton;