import { Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import BaseModal from '../../../components/modals/BaseModal';
import useProduct from '../../../hooks/api/useProduct';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import ContainedButton from '../../../components/actions/ContainedButton';
import useUserPolicy from '../../../hooks/api/useUserPolicy';
import UploadDocument from '../../../components/upload/UploadDocument';

const PolicyFileModal = (props) => {

    const {open, onClose, onRefresh, mode, product, origin, type} = props
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const [file, setFile] = useState()
    const userPolicyManager = useUserPolicy()

    useEffect(() => {
        if(open) onInitModule()
    },[open])

    const onImageDrop = (acceptedFiles) => {
        if(acceptedFiles.length){
            const _file = acceptedFiles[0]
            const newFile = Object.assign(_file, {
                preview: URL.createObjectURL(_file),
            });
            //console.log(newFile)
            if(_file){
                setFile(newFile)
            }
            //setFile(_data[0])dsfd sdjfkaldjklfasd  sdafa
        }
    }

    const onInitModule = async() => {
        setError(null)
        setFile(null)
        try {
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async() => {
        //console.log(form)
        
        if(!file){
            setError({
                message:'Por favor selecciona una imagen '
            })
            return
        }


        setSending(true)

        try {

            await userPolicyManager.actions.onUpdateFile(origin?.id_user_policy, file, type, )
            await onRefresh()

        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        onClose()

    }

    const isError = Boolean(error)

    let title = ''
    let filetype = ''
    switch(type){
        case 'invoice_xml':
            title='Subir archivo XML'
            filetype={'application/xml': ['.xml']}
            break;
        case 'invoice_pdf':
            title='Subir archivo PDF'
            filetype={'application/pdf': ['.pdf']}
            break;
        case 'payment_proof':
            title='Subir pago'
            filetype={'application/pdf': ['.pdf']}
            break;
        default:
            break;
    }


    return ( 
        <BaseModal maxWidth='xs'
        title={"Carga tu documento"}
        open={open} 
        onClose={onClose} >
            <div>
                <Stack spacing={1}>
                    <UploadDocument
                        accept={filetype}
                        error={isError}
                        file={file}
                        onDrop={onImageDrop}
                    />
                    <Grid container spacing={2}>
                        {error && 
                            <Grid item xs={12}>
                                <ErrorAlert
                                error={error} 
                                onClose={() => setError(null)} />
                            </Grid> 
                        }
                        <Grid item xs={12}>
                            <Stack direction='row' justifyContent='flex-end' >
                                <ContainedButton size='small'  loading={sending} onClick={onSubmit}>
                                    Subir
                                </ContainedButton>
                            </Stack>
                            
                        </Grid>
                    </Grid>
                </Stack>
            </div>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default PolicyFileModal;