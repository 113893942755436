import { public_server } from '../../utils/axios';


const useAuth = () => {   

    const actions = {
        onRecoverPasswordByEmail: async(data2send) => {
            await public_server.post('/user/passwordchange/request', data2send)
        },
        onValidatePasswordResetToken: async(data2send) => {
            await public_server.post('/user/passwordchange/validate', data2send)
        },
        onUpdatePasswordbyToken: async(data2send) => {
            await public_server.post('/user/passwordchange/execute', data2send)
        },
    }

    return {
        actions
    }
}
 
export default useAuth;