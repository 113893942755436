import React, { useCallback, useEffect, useState } from 'react'
import BaseModal from '../../../components/modals/BaseModal'
import { useParams } from 'react-router'
import { APPOINTMENT_STATUSES, USER_TYPES } from '../../../utils/enums'
import useAppointment from '../../../hooks/api/useAppointment'
import { onGetFullname, onGetUserTypeFromToken } from '../../../utils/general'
import { Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, alpha } from '@mui/material'
import useUser from '../../../hooks/api/useUser'
import { onGetDateWithTimezone } from '../../../utils/formatTime'
import OverviewPDFButton from './OverviewPDFButton'

const rowstable = [
    {id:'diet', name:'Plan'},
    {id:'imc', name:'IMC'},
    {id:'height', name:'Estatura (cm)'}, //
    {id:'weight', name:'Peso (kg)'}, //
    {id:'fat_mass', name:'Masa grasa (kg)'}, //
    {id:'percentage_fat', name:'Grasa (%)'}, // 
    {id:'fat_mass_index', name:'Índice de masa grasa (kg/m2)'}, //
    {id:'lean_mass', name:'Masa magra (kg)'}, //
    {id:'lean_mass_percentage', name:'Masa magra (%)'}, //
    {id:'lean_mass_index', name:'Índice de masa magra (kg/m2)'}, //
    {id:'muscle_mass', name:'Masa muscular (kg)'},
    {id:'muscle_mass_percentage', name:'Masa muscular (%)'},
    {id:'visceral_fat', name:'Grasa visceral (kg)'},
    {id:'bone_mass', name:'Masa ósea (kg)'},
    // Pliegues
    {id:'skinfold_tricep', name:'Tricep (mm)'},
    {id:'skinfold_subscapularis', name:'Subescapular (mm)'},
    {id:'skinfold_biceps', name:'Bicep (mm)'},
    {id:'skinfold_supraspinal', name:'Supraespinal (mm)'},
    {id:'skinfold_iliac_crest', name:'Cresta Iliaca (mm)'},
    {id:'skinfold_core', name:'Abdominal (mm)'},
    {id:'skinfold_front_thigh', name:'Muslo frontal (mm)'},
    {id:'skinfold_medial_calf', name:'Pantorrila medial (mm)'},

    ///////////////////// CIRCUNFERENCES /////////////////////////
    {id:'circumference_icc', name:'ICC (cm)'},
    {id:'circumference_head', name:'Cabeza (cm)'},
    {id:'circumference_neck', name:'Cuello (cm)'},
    {id:'circumference_relaxed_arm', name:'Brazo relajado (cm)'},
    {id:'circumference_tight_arm', name:'Brazo flexio y en tensión (cm)'},
    {id:'circumference_forearm', name:'Antebrazo (cm)'},
    {id:'circumference_wrist', name:'Muñeca (cm)'},
    {id:'circumference_chest', name:'Torax (cm)'},
    {id:'circumference_core', name:'Abdomen (cm)'},
    {id:'circumference_waist', name:'Cintura (cm)'},
    {id:'circumference_hips', name:'Cadera (cm)'},
    {id:'circumference_thigh_gluteus', name:'Muslo Gluteo (cm)'},
    {id:'circumference_thigh_troch', name:'Muslo Troch (cm)'},
    {id:'circumference_calf', name:'Pantorrilla (cm)'},
    {id:'circumference_ankle', name:'Tobillo (cm)'},


]

const AppointmentsOverviewModal = ({
    open, onClose
}) => {

    const appointmentManager = useAppointment()
    const userManager = useUser()

    const {iduser} = useParams()
    const [user, setUser] = useState()
    const [appointments, setAppointments] = useState([])

    const [metrics, setMetrics] = useState([])


    const userType = onGetUserTypeFromToken()

    const onGetUser = useCallback(async() => {
        try {
            let _user = await userManager.actions.onGet(iduser)
            setUser(_user)
        } catch (error) {
            
        }
    }, [iduser])

    const onGetAppointments = useCallback(async() => {
        const params = {
            order:0, 
            order_by:JSON.stringify(['start_date']),
            //order_by:'id_appointment',
            offset:0, limit:1000
        }
        let filter = {AND:[
            {OR:[
                {field:'appointment_status_id', operator:"!=", value:APPOINTMENT_STATUSES.COMPLETED},
                {field:'appointment_status_id', operator:"!=", value:APPOINTMENT_STATUSES.COMPLETED_NO_ASSISTANCE},
            ]},
        ]}

        let _appointmentsdata = []
        
        if(userType === USER_TYPES.ADMIN){
            filter.AND.push({field:'user_id', operator:'=', value:iduser})
            const _appointments = await appointmentManager.actions.onGetAll({
                ...params,
                filter:JSON.stringify(filter)
            })
            setAppointments(_appointments.data)
            console.log(_appointments.data)
            _appointmentsdata=_appointments.data
        }else{
            const _appointments = await appointmentManager.actions.onGetAllByNutritionist(iduser, {
                ...params,
                filter:JSON.stringify(filter)
            })
            setAppointments(_appointments.data)
            console.log(_appointments.data)
            _appointmentsdata=_appointments.data
        }

        /// Set available metrics
        let _updatedData = []

        for(let i=0; i<rowstable.length; i++){
            let current_metric = rowstable[i]
            if(current_metric.id !== "diet" && current_metric.id !== "imc"){
                const findEl = _appointmentsdata.find(el => {
                    const temp = el[current_metric.id]
                    if(current_metric.id === "circumference_calf"){
                        console.log(temp)
                    }
                    if(Boolean(temp)){
                        return parseFloat(temp) > 0
                    }
                    
                    return false
                    
                })
                current_metric.show = Boolean(findEl)   
            }
            if(current_metric.id === "diet"){
                current_metric.show = true
            }
            if(current_metric.id === "imc"){
                current_metric.show = true
            }
            _updatedData.push(current_metric)
           
        }

        console.log(_updatedData)
        setMetrics(_updatedData)
        
    },[userType, appointmentManager, setAppointments])

    useEffect(() => {
        if(open){
            console.log("Request appointments")
            onGetAppointments()
            onGetUser()
        }
    }, [open])

    const displayName = onGetFullname(user)    

    return ( 
        <BaseModal 
            maxWidth='lg'
            title={<>Resumen de consultas </>}
            open={open} 
            onClose={onClose} >

            <>
            
                <Stack direction='row'>
                    <Box sx={{flex:1}}>
                        <Typography variant='body2' color='text.secondary' >Nombre del paciente</Typography>
                        <Typography variant='subtitle1'>{displayName}</Typography>
                    </Box>
                    <Box>
                    <OverviewPDFButton
                        fullname={displayName}
                        appointments={appointments}
                        metrics={metrics}
                    
                    /> 
                    </Box>
                    
                </Stack>
                <Box sx={{
                    overflowX:'auto'
                }}>
                    <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                        <Table stickyHeader size={'small'} sx={{ minWidth: 800}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{
                                        minWidth:100, 
                                        background: theme => theme.palette.primary.main, 
                                        color:'white',
                                        position:'sticky',
                                        left:0,
                                        zIndex:10
                                    }}>
                                        Fecha de consulta
                                    </TableCell>
                                    {appointments.map(item => {
                                        return(
                                            <TableCell sx={{minWidth:180, background: theme => theme.palette.primary.main, color:'white'}} key={`rowhead-${item.id_appointment}`}>
                                                {onGetDateWithTimezone(item?.start_date, 'DD/MM/YYYY')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {metrics.map(item => {

                                   if(!item.show) return;
                                    
                                    return(
                                        <TableRow key={`row-${item.name}`}>
                                            <TableCell sx={{
                                                background: "#FEE4D3",
                                                fontWeight:600,
                                                borderBottom:'1px solid grey',
                                                borderRight:'1px solid grey',
                                                position:'sticky',
                                                left:0,
                                                zIndex:10
                                            }}>
                                                {item.name}
                                            </TableCell>
                                            {appointments.map((el, key) => {
                                                let _value = el[item.id]
                                                if(item.id === "diet"){
                                                    _value = el[item.id] || el.nutrify_diet
                                                }
                                                if(item.id ==="imc"){
                                                    if(!_value && el?.height && el?.weight){
                                                        const height = parseFloat(el?.height)/100
                                                        const weight = parseFloat(el?.weight)
                                                        _value = parseFloat(weight/(height*height)).toFixed(3)
                                                    }
                                                }
                                                return(
                                                    <TableCell key={`rowcell-${item.name}-${key}`}
                                                        sx={{
                                                            borderBottom:'1px solid grey',
                                                            borderRight:'1px solid grey'
                                                        }}
                                                    >
                                                        {_value}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    
                                    )
                                    
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>

                

        </BaseModal>
     );
}
 
export default AppointmentsOverviewModal;