import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import {makeStyles} from '@mui/styles'
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PisaManager from '../../hooks/pisa/pisaCoreApi';
import useDelay2Update from '../../hooks/useDelay2Update';
import LoadingContainer from '../loaders/LoadingContainer';
import ContainedButton from '../actions/ContainedButton';
import { catalogs } from '../../utils/catalogs';
import useUserAddress from '../../hooks/api/useUserAddress';
import { useSnackbar } from 'notistack';

const AddressForm = ({origin, type, user_id, onClose, onRefresh}) => {

    const pisaManager = PisaManager()
    const { control, register, handleSubmit, setValue, getValues } = useForm();
    const [calculating, setCalculating] = useState(false)
    const [sending, setSending] = useState(false)
    const [searching, setSearching] = useState(false)
    const [suburbans, setSuburbans] = useState([])
    const [done, setDone] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const userAddressManger = useUserAddress()


    useEffect(() => {
        if(origin){

        }
        setDone(true)
    }, [origin])
    
    const onRequestZipCodeSearch = useCallback(async(value)  => {
        if(searching) return
        setSearching(true)
        const data = getValues()
        try {
            const _result = await pisaManager.onGetZipCodeInfo(data.zip_code)
            console.log(_result)
            let _citi = null
            let _state = null
            let _suburbasns = []

            for(let i=0; i<_result.length; i++){
                const current = _result[i]
                _citi =  current?.municipionombre
                _state =  current?.estadonombre
                _suburbasns.push({
                    id:current?.nombre, label:current?.nombre
                })
            }
            setValue("suburb", null)
            setValue("municipality", _citi ?? null)
            setValue("state", _state ?? null)
            setSuburbans(_suburbasns)

        } catch (error) {
            console.log(error)
        }
        setSearching(false)
    }, [])

    const delayManager = useDelay2Update(onRequestZipCodeSearch)

    const onSubmit = useCallback(async() => {
        let data2send = getValues()
        if(data2send.state){
            const findEl = catalogs.states.find(el => el.search_string == data2send.state)
            if(findEl) data2send.state_id = findEl.value
        }

        if(type === 'add'){
            setSending(true)
            data2send.user_id = user_id
            data2send.contact_name = "."
            try {
                await userAddressManger.actions.onAdd(data2send)
                await onRefresh()
                onClose()
                enqueueSnackbar("Se agregó la dirección exitósamente", {variant:'success'})
            } catch (error) {
                enqueueSnackbar(error?.message, {variant:'error'})
            }
            setSending(false)
        }

    }, [getValues])


    return ( 
        <div>
            <LoadingContainer loading={!done}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            {...register("name")}
                            label="Alias"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("street")}
                            label="Calle"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            {...register("exterior_number")}
                            label="No. Ext"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            {...register("interior_number")}
                            label="No. Int"
                            
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name="zip_code"
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <TextField 
                                        label="C.P."
                                        value={field.value}
                                        inputRef={field.ref}
                                        fullWidth
                                        required
                                        onKeyUp={delayManager.onStartCountDown}
                                        onKeyDown={delayManager.onStopCountDown}
                                        onChange={(zipcode) => {
                                            field.onChange(zipcode);
                                        }}
                                        InputProps={{
                                            endAdornment:searching && <CircularProgress size={18} />
                                        }}
                                    
                                    />
                                )
                            }}
                        />
                        

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...register("suburb")}
                            select
                            label="Colonia"
                            required
                            fullWidth
                        >
                            {suburbans.map(item => {
                                return(
                                    <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            {...register("municipality")}
                            label="Ciudad"
                            required
                            fullWidth
                            disabled
                            InputLabelProps={{shrink:true}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            {...register("state")}
                            label="Estado"
                            required
                            fullWidth
                            disabled
                            InputLabelProps={{shrink:true}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        
                        <ContainedButton fullWidth onClick={onSubmit} loading={sending}>
                            {type === 'add' ? 'Agregar' : 'Guardar'}
                        </ContainedButton>
                    </Grid>
                </Grid>
            </LoadingContainer>
            
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}))
 
export default AddressForm;