import { useEffect, useRef } from "react";
import { CONFIG_SETTINGS } from "../config";
import { isValidDocument } from "./files";
import jwt_decode from "jwt-decode";
import { catalogs } from "./catalogs";

export const getRatio = (ratio = '1/1') => {
    return {
      '4/3': 'calc(100% / 4 * 3)',
      '3/4': 'calc(100% / 3 * 4)',
      '6/4': 'calc(100% / 6 * 4)',
      '4/6': 'calc(100% / 4 * 6)',
      '16/9': 'calc(100% / 16 * 9)',
      '9/16': 'calc(100% / 9 * 16)',
      '21/9': 'calc(100% / 21 * 9)',
      '9/21': 'calc(100% / 9 * 21)',
      '1/1': '100%',
    }[ratio];
}

export const onGetFullImagePath = (src) => {
  const valid = isValidDocument(src)
  if(!valid) return null
  return `${CONFIG_SETTINGS.S3_SERVER_URL}${src}`
}

export const onGetFullname = (user) => {
  let result = ''
  if(!user) return result
  if(user?.first_name)  result+=user?.first_name
  if(user?.fathers_last_name)  result= `${result} ${user?.fathers_last_name}`
  if(user?.mothers_last_name)  result= `${result} ${user?.mothers_last_name}`
  return result
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const string2Slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

  for (var i=0, l=from.length ; i<l ; i++)
  {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace('.', '-') // replace a dot by a dash 
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
    .replace(/-+/g, '-') // collapse dashes
    .replace( /\//g, '' ); // collapse all forward-slashes

  return str;
}

export const onGetUserTypeFromToken = () => {
    const token = localStorage.getItem('accessToken');
    let user_type = 0
    if(token){
      const decodedToken = jwt_decode(token)
      user_type = decodedToken?.user_type_id
    }
    return user_type
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export const onGetJSONData = async(datain) => {
  let result = null
  try {
    result = JSON.parse(datain)
  } catch (error) {
    console.log(error) 
  }
  return result
}

export const onGenerateRandomPassword = () => {
  var randomstring = Math.random().toString(36).slice(-8);
  return randomstring
}

export const onUpdateQuestionarySection = (origin, template) => {
  if(!origin) return origin
  let result = {...origin}
  try {
    result.module_name = template.module_name
    for(let i=0; i<result.questions.length; i++){
      const selected = template.questions[i]
      if(selected?.label){
        result.questions[i].label = selected.label
      }
      if(selected?.options){
        for(let j=0; j<selected.options.length; j++){
          if(selected.options[j].label){
            result.questions[i].options[j].label = selected.options[j].label
          }
          
        }
      }    
      
  
    }
  } catch (error) {
    
  }
 
  return result
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export function generateArrayOfArrays(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
  }
  return result;
}

export const onCalculateCalories = (diet, meals) => {
  let _calories = 0
  let _equivalentscat = diet?.enable_smae_equivalents ? catalogs.equivalents_smae : catalogs.equivalents

  console.log("selected catalgo", _equivalentscat)

  _equivalentscat.forEach(item => {
      const _key = item?.key
      meals.forEach(el => {
          let _value = parseFloat(el[_key]) ??0
          if(isNaN(_value)) _value = 0
          _calories += (_value*item?.calories)
      })
  })

  return _calories
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}