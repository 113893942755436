import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server } from '../../utils/axios';
import { fileTypeByUrl } from '../../utils/files';
import FileSaver from 'file-saver';


const useUserAddress = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/address/all', {params:params})
            result.data = request.data.data?.addresss??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(idaddress) => {
            let result = null
            const request = await private_server.get(`/address/${idaddress}`)
            result = request.data.data.address
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/address', data2send)
            result = request.data.data?.address?.id_address
            return result
        },
        onEdit: async(idaddress, data2send) => {
            await private_server.patch(`/address/${idaddress}`, data2send)
        },
        onDelete: async(idaddress) => {
            await private_server.delete(`/address/${idaddress}`)
        },
        onGetStates: async() => {
            const request = await private_server.get('/address/states')
            return request.data.data.states
        }
    }

    return {
        actions
    }
}
 
export default useUserAddress;