import { encodeBase64 } from "./utils/encode";
/// API

export const celuQrPrefix = 'celu#'

export const CONFIG_SETTINGS = {
    HOST_URL:process.env.REACT_APP_HOST_URL,
    API_URL:process.env.REACT_APP_API_URL,
    API_USERNAME:process.env.REACT_APP_API_USERNAME,
    API_PASSWORD:process.env.REACT_APP_API_PASSWORD,
    API_BEARER:`Basic ${encodeBase64(process.env.REACT_APP_API_USERNAME+':'+process.env.REACT_APP_API_PASSWORD)}`,
    S3_SERVER_URL:process.env.REACT_APP_S3_SERVER_URL,
    CART_QR_PREFIX:`${celuQrPrefix}cart`,
    RECIPE_QR_PREFIX:`${celuQrPrefix}recipe`,
    ORDER_QR_PREFIX:`${celuQrPrefix}order`,
    SLOT_SIZE:30,
    PISA_API_URL:"https://centralqa1q.grupopisa.net/InterCMaS_VIZNE/webresources/api/v1"
}

export const APP_VERSION = "1.0.5"

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    H_MOBILE: 64,
    H_MAIN_DESKTOP: 88,
    H_DASHBOARD_DESKTOP: 92,
    H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
  };
  
  export const NAV = {
    W_BASE: 260,
    W_DASHBOARD: 280,
    W_DASHBOARD_MINI: 88,
    //
    H_DASHBOARD_ITEM: 48,
    H_DASHBOARD_ITEM_SUB: 36,
    //
    H_DASHBOARD_ITEM_HORIZONTAL: 32,
  };
  
  export const ICON = {
    NAV_ITEM: 24,
    NAV_ITEM_HORIZONTAL: 22,
    NAV_ITEM_MINI: 22,
  };
  