import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import useClient from "../../../hooks/api/useClient"
import useClientLocation from "../../../hooks/api/useClientLocation"
import useClientProgram from "../../../hooks/api/useClientProgram"
import useUser from "../../../hooks/api/useUser"
import useForm from "../../../hooks/useForm"
import { catalogs } from "../../../utils/catalogs"
import { USER_STATUSES, USER_TYPES } from "../../../utils/enums"
import { isValidDocument } from "../../../utils/files"
import { onGetCatalogs } from "../../../utils/forms"
import { onGetUserTypeFromToken, sleep } from "../../../utils/general"
import useNutritionistClient from "../../../hooks/api/useNutritionistClient"

const useBusinessEmployeePage = () => {

    const {iduser} = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const formManager = useForm(form_data)
    const passwordController = useForm(password_formdata)
    const userManager = useUser()
    const nutritionistClientManager = useNutritionistClient()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [currentTab, setCurrentTab] = useState('policies');

    const [account, setAccount] = useState()
    const [active, setActive] = useState(false)
    const [file, setFile] = useState()

    const [clients, setClients] = useState([])
    const [locations, setLocations] = useState([])
    const [programs, setPrograms] = useState([])

    const clientManager = useClient()
    const locationManager = useClientLocation()
    const programManager = useClientProgram()

    const userType = onGetUserTypeFromToken()

    const {form, onValidateForm, onGetFormData, onInitForm, onUpdateDBErrors} = formManager

    useEffect(() => {
        actions.onInitModule()
    },[iduser])

    const onAutosave = async() => {
        try {
            await sleep(300)
            console.log('se autoguardo')
            await actions.onSubmit({disabled_notification:true})
        } catch (error) {
            
        }
    }

    useEffect(() => {
        const {form} = passwordController
        const {password, passwordConfirmation} = form
        let _form = {...form}
        if(password?.value !== passwordConfirmation.value){
            _form.passwordConfirmation.isValid = false
        }else{
            _form.passwordConfirmation.isValid = true
        }
        passwordController.setForm(_form)


    }, [passwordController.form.password,passwordController.form.passwordConfirmation])

    const onGetNutritionistList = async(client_id) => {
        let catalog = []
        if(userType === USER_TYPES.ADMIN){
            const _nutritionist = await nutritionistClientManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['user_first_name']),
                filter:JSON.stringify({AND:[
                    {field:"client_id", operator:"=", value:client_id}
                ]})
            })
            catalog = onGetCatalogs(_nutritionist.data, 'user_id', 'user_first_name', 'user_fathers_last_name', 'user_mothers_last_name')
            console.log(_nutritionist)
            
        }
        return catalog
    }


    const onGetInitialCatalogs = useCallback(async(_user) => {
        let _clientsCat = []
        let _catalogs = []
        
        if(userType === USER_TYPES.ADMIN){
            const _clients = await clientManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['name'])
            })
            _clientsCat = onGetCatalogs(_clients.data, 'id_client', 'name')
            const _locations = await locationManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['name'])
            })

            const _programs = await programManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['name'])
            })
            
            setLocations(_locations.data)
            setPrograms(_programs.data)

            const nutritionist_catalog = await onGetNutritionistList(_user.client_id)

            _catalogs = [
                {
                    key:'client_id', 
                    options:_clientsCat,
                    forceValue:true,
                    value: _user?.client_id ? {value:_user?.client_id ,label:_user?.client} : null
                },
                {
                    key:'client_location_id', 
                    options:[],
                    forceValue:Boolean(_user?.client_location_id),
                    value: _user?.client_location_id ? {value:_user?.client_location_id ,label:_user?.client_location} : null
                },
                {
                    key:'client_program_id', 
                    options:[],
                    forceValue:Boolean(_user?.client_program_id),
                    value: _user?.client_program_id ? {value:_user?.client_program_id ,label:_user?.client_program} : null
                }
            ]

            if(nutritionist_catalog.length){

                const elnut = nutritionist_catalog.find(el => el.value == _user?.nutritionist_id)
                console.log("nutriologooooooooo....",_user?.nutritionist_id, elnut, nutritionist_catalog)

                _catalogs.push({
                    key:'nutritionist_id', 
                    options:nutritionist_catalog,
                    forceValue:Boolean(elnut),
                    value: elnut ? elnut : null
                })
            }

        }else{
            let _locationCat = []
            let _programCat = []
            if(_user?.client_id) _clientsCat = [{value:_user?.client_id ,label:_user?.client}]
            if(_user?.client_location_id) _locationCat = [{value:_user?.client_location_id ,label:_user?.client_location}]
            if(_user?.client_program_id) _programCat = [{value:_user?.client_program_id ,label:_user?.client_program}]
            const _locations = await locationManager.actions.onGetAllNutritionist(_user?.id_user, {
                order:0, 
                order_by:JSON.stringify(['name'])
            })
            const _programs = await programManager.actions.onGetAllNutritionist(_user?.id_user,{
                order:0, 
                //order_by:JSON.stringify(['name'])
            })
            setLocations(_locations.data)
            setPrograms(_programs.data)

            //const _locations = await locationManager.actions.onGetAll({
            //    order:0, 
            //    order_by:JSON.stringify(['name']),
            //    filter:JSON.stringify({AND:[
            //        {field:'client_id', operator:"=", value:_user?.client_id}
            //    ]})
            //})

            _catalogs = [
                {
                    key:'client_id', 
                    options:_clientsCat,
                    forceValue:true,
                    value: _user?.client_id ? {value:_user?.client_id ,label:_user?.client} : null
                },
                {
                    key:'client_location_id', 
                    options:[],
                    forceValue:Boolean(_user?.client_location_id),
                    value: _user?.client_location_id ? {value:_user?.client_location_id ,label:_user?.client_location} : null
                },
                {
                    key:'client_program_id', 
                    options:[],
                    forceValue:Boolean(_user?.client_program_id),
                    value: _user?.client_program_id ? {value:_user?.client_program_id ,label:_user?.client_program} : null
                }
            ]
        }
        setClients(_clientsCat)
        return _catalogs
    }, [userType, setLocations, setPrograms, setClients])

    


    const actions = {
        onInitModule: async() => {
            setLoading(true)
            let _user 
            try {
                _user = await userManager.actions.onGet(iduser)
                
                setAccount(_user)
                const _catalogs = await onGetInitialCatalogs(_user)
                onInitForm(_user, {catalogs:_catalogs})
                passwordController.onResetForm()
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            if(_user){
                console.log('Hay ususario', _user, _user?.image)
                onGetUserStatus(_user)
                onGetUserImage(_user)
            }
            setLoading(false)
        },
        onReset: () => passwordController.onResetForm(),
        onRefresh: async() => {
            try {
                let _user 
                _user = await userManager.actions.onGet(iduser)
                setAccount(_user)
                onInitForm(_user)
                if(_user){
                    console.log('Hay ususario', _user, _user?.image)
                    onGetUserStatus(_user)
                    onGetUserImage(_user)
                }
                const _catalogs = await onGetInitialCatalogs(_user)
                onInitForm(_user, {catalogs:_catalogs})

            } catch (error) {
                
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
        },
        onAvatarDrop: (acceptedFiles) => {
            if(acceptedFiles.length){
                const _file = acceptedFiles[0]
                const newFile = Object.assign(_file, {
                    preview: URL.createObjectURL(_file),
                });
                //console.log(newFile)
                if(_file){
                    setFile(newFile)
                }
                //setFile(_data[0])
            }
        },
        onInputChange: (_input) => {
            if(_input.config.id !== 'client_id'){
                formManager.onChangeForm(_input)
            }else{
                console.log('client', _input)

                let _client_location_id = {...form.client_location_id}
                _client_location_id.value = ''
                let _client_program_id = {...form.client_program_id}
                _client_program_id.value = ''
                const data2send = [
                    _input, _client_location_id, _client_program_id
                ]
                console.log(data2send)
                formManager.onChangeMultipleForm(data2send)
            }
            
        },
        onPasswordInputChange: (_input) => {
            passwordController.onChangeForm(_input)
        },
        onChangeSection: (value) => {
            setCurrentTab(value)
        },
        onChangeUserStatus: () => setActive(!active),
        onSubmit: async(settings) => {
            const valid = onValidateForm(form)
            const iduser = account?.id_user
            if(!valid){
                setError({message:'Debe llenar todos los campos solicitados'})
                return
            }
    
            let data2send = onGetFormData({origin: account, eraseEmptySpaces:true})
            console.log(data2send)
            delete data2send.cliend_id
            if(userType === USER_TYPES.NUTRITIONIST){
                delete data2send.nutritionist_id
            }

            let changeUserStatus = 0
            if(account?.user_status_id < USER_STATUSES.INVITED){ // If is active or suspend
                console.log('estado de active es', active)
                if(active && account?.user_status_id === USER_STATUSES.SUSPEND) changeUserStatus = USER_STATUSES.ACTIVE
                if(!active && account?.user_status_id === USER_STATUSES.ACTIVE) changeUserStatus = USER_STATUSES.SUSPEND
            }

            if(changeUserStatus > 0)  console.log('Hubo cambio de estatus de usuario', changeUserStatus)


            

            setSending(true)

            //delete data2send.client_id
            //delete data2send.client_location_id
            //delete data2send.client_program_id

            try {
                delete data2send.cliend_id
                await userManager.actions.onEdit(iduser, data2send)
                if(file) await userManager.actions.onUpdatePicture(iduser, file)
                if(changeUserStatus > 0)
                    changeUserStatus === 1 
                        ? await userManager.actions.onEnable(iduser)
                        : await userManager.actions.onDisable(iduser)
                
                if(!settings?.disabled_notification){
                    await actions.onRefresh()
                    enqueueSnackbar('Información actualizada exitosamente', {variant:"success"})
                }
                

            } catch (error) {
                console.log(error)
                if(error.details) onUpdateDBErrors(error.details)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
                setSending(false)
            }
            setSending(false)
        },
        onUpdatePassword: async() => {
            const valid = onValidateForm(passwordController.form)
            
            if(!valid){
                enqueueSnackbar('Debe llenar todos los campos solicitados', {variant:"error"})
                return
            }
    
            let data2send = passwordController.onGetFormData()

            if(data2send.password !== data2send.passwordConfirmation){
                enqueueSnackbar('Las contraseñas no coinciden', {variant:"error"})
                return
            }
            if(data2send.new_password !== data2send.passwordConfirmationProfile){
                enqueueSnackbar('Las contraseñas no coinciden', {variant:"error"})
                return
            }
    
            setSending(true)

            

            try {
                await userManager.actions.onEdit(iduser, data2send)
                if(file) await userManager.actions.onUpdatePicture(iduser, file)

                await actions.onRefresh()
                await passwordController.onResetForm()
                enqueueSnackbar('Contraseña actualizada exitosamente', {variant:"success"})

            } catch (error) {
                console.log(error)
                if(error?.details) onUpdateDBErrors(error.details)
                setError(error)
                setSending(false)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onAutosave
    }

    const onGetUserStatus = useCallback((_user) => {
        setActive(_user?.user_status_id === USER_STATUSES.ACTIVE )
    }, [setActive])

    const onGetUserImage = useCallback(async(_user) => {
        try {
            if(isValidDocument(_user?.image)){
                const image = await userManager.actions.onGetBlobFile(_user?.image)
                actions.onAvatarDrop(image)
            }
        } catch (error) {
            console.log(error)
        }
    },[userManager, actions])


    const system = {loading, sending, error}
    const data = {currentTab, file, account, active, locations, programs}
    const passwordForm = passwordController.form

    return {
        system,
        actions, 
        data,
        form,
        passwordForm
    };
}
 
export default useBusinessEmployeePage;

const password_formdata = {
    previous_password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña previa',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña previa',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    
    new_password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        injectData:'passwordConfirmationProfile',
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
            label:'Nueva contraseña',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        injectData:'passwordConfirmation',
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña',
            helperText:'La contraseña debe de ser de al menos 8 caracteres'
        },
        rules: {type: 'distance', min:8, max:256}
    },
    passwordConfirmationProfile: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        compareTarget:'new_password',
        config: {
            id: 'passwordConfirmationProfile',
            type: 'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules: {type: 'equals'}
    },
    passwordConfirmation: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        compareTarget:'password',
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules: {type: 'equals'}
    },
}

const form_data = {
    first_name: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Nombre(s)',
            placeholder:'Nombre(s)',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    fathers_last_name: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Apellido paterno',
            placeholder:'Apellido paterno',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    mothers_last_name: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Apellido materno',
            placeholder:'Apellido materno',
            helperText:'Debe ser máximo de 255 caracteres'
        },
        rules: {type: 'distance', min:0, max:255}
    },
    birth_date: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Fecha de nacimiento',
            placeholder:'Fecha de nacimiento',
            helperText:'Fecha no válida'
        },
        rules: {type: 'date'}
    },
    employee_number: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'employee_number',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'No. de empleado',
            placeholder:'No. de empleado',
            helperText:'Debe ser máximo de 255 caracteres'
        },
        rules: {type: 'distance', min:0, max:255}
    },
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Email',
            autoComplete:"new-password",
            placeholder:'Ingresa tu correo electrónico',
            helperText:'Correo no válido'
        },
        rules: {type: 'email'}
    },
    gender_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:catalogs.genders,
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label:'Género',
            autoComplete:"new-password",
            placeholder:'Selecciona tu género',
            helperText:'Género no válido, seleccione una opción'
        },
        rules: {type: 'select'}
    },
    nutritionist_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'nutritionist_id',
            type: 'autocomplete',
            fullWidth: true,
            label:'Nutriolog@',
            autoComplete:"new-password",
            placeholder:'Nutriolog',
            helperText:'Nutriologa no válido, seleccione una opción'
        },
        rules: {type: 'select'}
    },
    client_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'client_id',
            type: 'autocomplete',
            fullWidth: true,
            label:'Empresa',
            autoComplete:"new-password",
            placeholder:'Selecciona una empresa',
            helperText:'Género no válido, seleccione una opción'
        },
        rules: {type: 'select'}
    },
    client_location_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'client_location_id',
            type: 'autocomplete',
            fullWidth: true,
            label:'Sitio',
            autoComplete:"new-password",
            placeholder:'Selecciona un sitio',
            helperText:'Género no válido, seleccione una opción'
        },
        rules: {type: 'none'}
    },
    client_program_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'client_program_id',
            type: 'autocomplete',
            fullWidth: true,
            label:'Programa',
            autoComplete:"new-password",
            placeholder:'Selecciona un programa',
            helperText:'Género no válido, seleccione una opción'
        },
        rules: {type: 'none'}
    },
    mobile_country_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:catalogs.mobile_country_codes,
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label:'Cod. País',
            autoComplete:"new-password",
            placeholder:'+52',
            helperText:''
        },
        rules: {type: 'select'}
    },
    mobile: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'mobile',
            type: 'tel',
            fullWidth: true,
            label:'Celular',
            autoComplete:"new-password",
            placeholder:'5511223344',
            helperText:'Número móvil no válido'
        },
        rules: {type: 'phone_number'}
    },
    details: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            multiline:true,
            rows:6,
            label:'Detalles',
            placeholder:'Escriba un poco más sobre este usuario',
            helperText:'Debe ser un valor entre 1 a 512 caracteres'
        },
        rules: {type: 'distance', min:0, max:512}
    },
    
}
