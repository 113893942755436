import React, { useCallback, useEffect, useState } from 'react';
import {  Card, Grid, Stack, Typography, Switch } from '@mui/material';
import UploadAvatarForm from '../../../../components/forms/UploadAvatarForm';
import { fData } from '../../../../utils/formatNumber';
import InputForm from '../../../../components/forms/InputForm';
import ContainedButton from '../../../../components/actions/ContainedButton';
import InputSelectForm from '../../../../components/forms/InputSelectForm';
import AutocompleteForm from '../../../../components/forms/AutocompleteForm';
import { onGetCatalogs } from '../../../../utils/forms';
import { USER_STATUSES, USER_TYPES } from '../../../../utils/enums';
import useNutritionistClient from '../../../../hooks/api/useNutritionistClient';
import { onGetUserTypeFromToken } from '../../../../utils/general';
import { useParams } from 'react-router';
import useClientLocation from '../../../../hooks/api/useClientLocation';
import LoadingContainer from '../../../../components/loaders/LoadingContainer';

const GeneralAccountSection = ({
    file, form, loading, active, account,
    onInputChange,
    onChangeUserStatus,
    onAvatarDrop,
    onSubmit,
    locations=[],
    programs
}) => {

    const nutritionistClientManager = useNutritionistClient()


    const [_locations, setLocations] = useState([])
    const [_programs, setPrograms] = useState([])
    const [_nutritionists, setNutritionists] = useState([])
    const {idclient} = useParams()
    const clientLocationManager = useClientLocation()

    const onGetLocations = useCallback(async() => {
        let result = []
        try {
            let filter = {
                order:0, 
                order_by:JSON.stringify(['name']),
                filter:JSON.stringify({AND:[
                    {field:'client_id', operator:"=", value:idclient}
                ]})
            }
            const _clients = await clientLocationManager.actions.onGetAll(filter)
            result = onGetCatalogs(_clients.data, 'id_client_location', 'name')
        } catch (error) {
            console.log(error)
        }
        return result
    }, [idclient])


    useEffect(() => {
        const onRefreshCatalogs = async() =>{
            console.log("Nueov clienteee, ", idclient)
            const temp = await onGetLocations()
            setLocations(temp)
        }
        onRefreshCatalogs()
    }, [idclient])

    const enableLocations = _locations?.length > 0

    form.client_location_id.options = _locations


    return ( 
        <div>
            <LoadingContainer loading={loading}>

            
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                        
                        <UploadAvatarForm
                            name="photoURL"
                            maxSize={3145728}
                            file={file}
                            onDrop={onAvatarDrop}
                            helperText={
                                <Typography
                                variant="caption"
                                sx={{
                                    mt: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                                >
                                Archivos permitidos *.jpeg, *.jpg, *.png, *.gif
                                <br /> Tamaño máx. {fData(3145728)}
                                </Typography>
                            }
                        /> 
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 3 }}>
                        <>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant='subtitle2' sx={{paddingBottom:3}}>Información básica</Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction='row' alignItems='center'>
                                        <Typography variant='body2'>¿Activo?</Typography>
                                        <Switch disabled={account?.user_status_id > USER_STATUSES.SUSPEND} checked={active} onChange={onChangeUserStatus} />
                                    </Stack>
                                </Grid>
                            </Grid>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.first_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.fathers_last_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.mothers_last_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.birth_date} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputSelectForm data={form.gender_id} onChange={onInputChange} />
                                </Grid>
                                {enableLocations && (
                                    <Grid item xs={12}>
                                        <AutocompleteForm data={form.client_location_id} onChange={onInputChange} />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <InputForm data={form.email} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.employee_number} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <InputSelectForm data={form.mobile_country_id} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={8} md={9}>
                                    <InputForm data={form.mobile} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputForm data={form.details} onChange={onInputChange} />
                                </Grid>
                            </Grid>
                        </>
                        
                        <Stack alignItems='flex-end' sx={{pt:3}}>
                            
                            <ContainedButton loading={loading} onClick={onSubmit}>
                                Guardar
                            </ContainedButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
            </LoadingContainer>
        </div>
     );
}
 
export default GeneralAccountSection;