import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
import useUser from '../hooks/api/useUser';
// utils
import { private_server, public_server } from '../utils/axios';
import { ROLES, USER_TYPES } from '../utils/enums';
import { sleep } from '../utils/general';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'REFRESH') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userManager = useUser()

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        await sleep(300)
        const user = await userManager.actions.onGetMe()
        //const {user} = user_response.data.data

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    const response = await public_server.post('/login', data);

    const { token, user_id, user_type_id} = response.data.data;
    //console.log('USER ID', user_id)
    
    setSession(token);
    await sleep(300)
    const user = await userManager.actions.onGetMe()    

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    
//
    setSession(token);
    //const user_request = await private_server(`/`)
//
    
  };

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await public_server.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  };

  const refreshUser = async() => {
    const user = await userManager.actions.onGetMe()

    dispatch({
      type: 'REFRESH',
      payload: {
        user
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
