import { useRef, useState } from "react";

const useDelay2Update = (onUpdate) => {

    const timerRef = useRef()

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onRequestSearch, 500);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onRequestSearch = () => {
        if(timerRef.current) clearTimeout(timerRef.current) 
        onUpdate()
    }

    return {
        onStartCountDown,
        onStopCountDown,
    };
}
 
export default useDelay2Update;