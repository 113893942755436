import PropTypes from 'prop-types';
import Image from '../../images/Image';
import { Box, Stack, Typography } from '@mui/material';
import Iconify from '../../iconify/Iconify';
import { amber, blueGrey } from '@mui/material/colors';
import { formatBytes } from '../../../utils/general';
//

// ----------------------------------------------------------------------

SingleDocumentPreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleDocumentPreview({ file, originalSrc }) {
  if (!file && !originalSrc) {
    return null;
  }
  console.log(file)
  return (
    <Box
      alt="file preview"
      sx={{
        top: '50%',
        left: '50%',
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        transform:'translate(-50%,-50%)'
      }}
    >
      <Stack alignItems='center'>
        <Iconify icon='fluent:document-28-filled' width={42} sx={{color:blueGrey[700]}} />
        <Typography variant='subtitle2' align='center'>{file?.name}</Typography>
        <Typography variant='body2' align='center'>{formatBytes(file?.size)}</Typography>
      </Stack>
    </Box>
  );
}
