import { alpha, styled } from '@mui/material/styles';import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  //right: 0,
  //bottom: 0,
  zIndex: 10,
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.background.default,0.7),

}));

// ----------------------------------------------------------------------

export default function CircularLoadingContainer({loading}) {

  if(!loading) return

  return (
    <>
      <StyledRoot>
        <CircularProgress size={48} />
      </StyledRoot>
      
    </>
  );
}
