import { Box, Card, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import TableEmptyRows from '../../../../components/table/TableEmptyRows';
import TableHeadCustom from '../../../../components/table/TableHeadCustom';
import TableNoData from '../../../../components/table/TableNoData';
import TablePaginationCustom from '../../../../components/table/TablePaginationCustom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { onGetFullname } from '../../../../utils/general';
import { emptyRows } from '../../../../utils/tables';
import DateRangePicker from '../../../../components/date-range-picker';
import { useDateRangePicker } from '../../../../components/date-range-picker';
import { endOfMonth, startOfDay, startOfMonth, subMonths, subYears } from "date-fns";
import useModal from '../../../../hooks/useModal';
import FileFilterButton from '../../../../components/actions/FileFilterButton';
import Iconify from '../../../../components/iconify/Iconify';
import { onGetDateWithTimezone } from '../../../../utils/formatTime';
import moment from 'moment-timezone'
import CircularLoadingContainer from '../../../../components/loaders/CircularLoadingContainer';
import useAnalytics from '../../../../hooks/api/useAnalytics';
import Label from '../../../../components/label/Label';
import { catalogs } from '../../../../utils/catalogs';
import { onGetRangeAge } from '../../../../utils/platform';
import { onGetFormatNumber } from '../../../../utils/formatNumber';
import { onGetCatalogs } from '../../../../utils/forms';
import useClient from '../../../../hooks/api/useClient';
import CustomSmallSelect from '../../../../components/custom-input/CustomSmallSelect';

const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'name', label: 'Nombre', align: 'left' },
    { id: 'client', label: 'Cliente', align: 'left' },
    { id: 'location', label: 'Sitio', align: 'left' },
    { id: 'program', label: 'Programa', align: 'left' },
    { id: 'nutritionist', label: 'Nutrióloga', align: 'left' },
    { id: 'status', label: 'Estatus', align: 'left' },
    { id: 'start_date', label: 'Fecha de inicio', align: 'left' },
    { id: 'motive', label: 'Motivo de consulta', align: 'left' },
    { id: 'gender', label: 'Género', align: 'left' },
    { id: 'age', label: 'Rango de edad', align: 'left' },
    { id: 'weight', label: 'Peso', align: 'left' },
    { id: 'height', label: 'Talla', align: 'left' },
    { id: 'imc', label: 'IMC', align: 'left' },
    { id: 'imc_diagnostic', label: 'Diagnóstico IMC', align: 'left' },
    { id: 'icc', label: 'ICC', align: 'left' },
    { id: 'icc_diagnostic', label: 'Diagnóstico ICC', align: 'left' },
    { id: 'fat_percentage', label: '% de grasa', align: 'left' },
    { id: 'fat_weight', label: 'Kg de grasa', align: 'left' },
    { id: 'muscle_weight', label: 'Kg de músculo', align: 'left' },
    { id: 'appoinments_count', label: '# de consultas', align: 'left' },
    { id: 'risk_group', label: 'Grupo de riesgo', align: 'left' },
    { id: 'digestion', label: 'Digestión', align: 'left' },
    { id: 'physical_condition', label: 'Condición física', align: 'left' },
    { id: 'sleep_quality', label: 'Calidad del sueño', align: 'left' },
    { id: 'energy_level', label: 'Nivel de energía', align: 'left' },
];

const UsersAnalyticsSection = () => {

    const [loading, setLoading] = useState(false)
    const [analytics, setAnalytics] = useState([])
    const [client, setClient] = useState(-1)
    const [clients, setClients] = useState([])
    const [count, setCount] = useState(0)
    const [done, setDone] = useState(false)

    const today = new Date()

    const dateRangeManager = useDateRangePicker(subMonths(startOfMonth(today),3),endOfMonth(today))
    const analyticManager = useAnalytics()
    const clientManager = useClient()

    const tableController = useTableSettings({
        initial_filter:{},
        initial_params:{
            //order_by:'id_user',
            //order:1,
            limit:25, 
            offset:0,
        }
    }) 

    const modalManager = useModal({
        date_selector:false
    })

    const {modals} = modalManager

    const onChangePagination = useCallback((_data) => {
        tableController.setPagination(_data)
    }, [])

    const onGetAnalytis = useCallback(async() => {
        setLoading(true)
        const _startdate = onGetDateWithTimezone(dateRangeManager.startDate.toISOString()).tz(moment.tz.guess()).toISOString()
        const _enddate = onGetDateWithTimezone(dateRangeManager.endDate.toISOString()).tz(moment.tz.guess()).toISOString()

        let _params = {
            ...tableController.pagination,
            start_date:_startdate,
            end_date:_enddate,
            
        }
        if(client !== -1) _params.client_id = client
        _params.offset = _params.offset * _params.limit
        //console.log(_params)

        const _analytics = await analyticManager.actions.onGetUsersExtended(_params)
        console.log(_analytics.data)
        setAnalytics(_analytics.data)
        setCount(_analytics.count)
        setLoading(false)

    },[tableController, dateRangeManager, client])

    const onChangeStartDate = (value) => {
        tableController.onUpdatePaginationValue('offset', 0)
        dateRangeManager.onChangeStartDate(value)
    }

    const onChangeEndDate = (value) => {
        tableController.onUpdatePaginationValue('offset', 0)
        dateRangeManager.onChangeEndDate(value)
    }

    const initialize = useCallback(async() => {
        const _clients = await clientManager.actions.onGetAll({order:0, order_by:JSON.stringify(['name'])})
        const clientsCatalogs = onGetCatalogs(_clients?.data,'id_client', 'name')
        setClients([{value:-1, label:'Todos'}, ...clientsCatalogs])
        setDone(true)
        //await onGetAnalytis()
    }, [onGetAnalytis])

    useEffect(() => {
        if(done) onGetAnalytis()
    }, [done, client])

    useEffect(() => {
        initialize()
    }, [])


    const {filter, pagination} = tableController

    const page = pagination.offset
    const rowsPerPage = pagination.limit

    const dense = true
    const denseHeight = dense ? 52 : 72;
    const isNotFound = !analytics.length

    const onCloseDatePicker = useCallback(() => {
        modalManager.onChangeModalStatus('date_selector', false)
        onGetAnalytis()
    }, [])

    const onUpdateClient = (e) => setClient(parseInt(e.target.value))


    return ( 
        <Box>
            <DateRangePicker
                variant="calendar"
                startDate={dateRangeManager.startDate}
                endDate={dateRangeManager.endDate}
                open={modals.date_selector}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                onClose={onCloseDatePicker} 
            />
            
            <Box>
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                        <CustomSmallSelect label='Clientes' value={client} onChange={onUpdateClient}>
                            {clients.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </CustomSmallSelect>
                    </Grid>
                    <Grid item>
                        <FileFilterButton
                            //isSelected={!!isSelectedValuePicker}
                            startIcon={<Iconify icon="eva:calendar-fill" />}
                            onClick={() => modalManager.onChangeModalStatus('date_selector', true)}
                            >
                            {dateRangeManager.shortLabel}
                        </FileFilterButton>
                    </Grid>
                </Grid>
            </Box>
            <Card sx={{overflow:'auto', mt:2, position:'relative'}}>
                <CircularLoadingContainer loading={loading} />
                <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                    
                        <Table stickyHeader size={'small'} sx={{ minWidth: 3500}}>
                            <TableHeadCustom
                                order={pagination.order}
                                orderBy={pagination.order_by}
                                headLabel={TABLE_HEAD}
                                rowCount={count}
                                numSelected={0}
                            />

                            <TableBody>
                                {analytics.map((row) => (
                                    <UserAnalyticTableRow
                                        key={`user-item-${row?.user_id}`}
                                        row={row}
                                    />
                                ))}

                                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, count)} />

                                <TableNoData isNotFound={isNotFound} />
                            </TableBody>
                        </Table>
                </TableContainer>

                
            </Card>
            <Box sx={{marginTop:2}}>
                <TablePaginationCustom    
                    tableFilter={tableController.pagination}               
                    total={count}
                    onChangePagination={onChangePagination}
                />
            </Box>
        </Box>
     );
}
 
export default UsersAnalyticsSection;


const UserAnalyticTableRow = ({ row, selected}) => {

    const _status = catalogs.user_statuses.find(el => el.value === row?.user_status_id)

  
    return (
      <>
        <TableRow hover selected={selected}>
            <TableCell sx={{cursor:'pointer'}} padding="checkbox">
                <Typography variant="subtitle2" noWrap>
                    {row?.user_id}
                </Typography>
            </TableCell>
    
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="subtitle2" noWrap>
                    {onGetFullname(row)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {row?.client ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.client_location ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.client_program ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="subtitle2" noWrap>
                    {onGetFullname({
                        first_name:row?.nutritionist_first_name, 
                        fathers_last_name:row?.nutritionist_fathers_last_name,
                        mothers_last_name:row?.nutritionist_mothers_last_name,
                    })}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Label
                    variant="soft"
                    color={_status?.color}
                    sx={{ textTransform: 'capitalize' }}
                >
                    {_status?.label}
                </Label>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {onGetDateWithTimezone(row?.start_date, 'DD MMM, YY')}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.objective ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.gender ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {onGetRangeAge(row?.birth_date)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {onGetFormatNumber(row?.weight)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.height)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.imc)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.imc_diagnostic ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.icc)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.icc_diagnostic ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.fat_mass_percentage)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.fat_mass)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                    {onGetFormatNumber(row?.muscle_mass)}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.appointments_count ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.risk_level ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.digestion_status ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.physical_condition ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.sleep_quality ?? ''}
                </Typography>
            </TableCell>
            <TableCell sx={{cursor:'pointer'}} >
                <Typography variant="body2" noWrap>
                {row?.energy_level ?? ''}
                </Typography>
            </TableCell>
            
        </TableRow>
        
      </>
    );
  }
  