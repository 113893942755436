import { Typography, Stack, Alert } from '@mui/material';
import {makeStyles} from '@mui/styles'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router';
import PasswordIcon from '../../../assets/icons/PasswordIcon';
import ContainedButton from '../../../components/actions/ContainedButton';
import ShowPasswordBtn from '../../../components/actions/ShowPasswordBtn';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import Logo from '../../../components/brandbook/Logo';
import InputForm from '../../../components/forms/InputForm';
import useAuth from '../../../hooks/api/useAuth';
import useForm from '../../../hooks/useForm';
import CompactContainer from '../../../layouts/CompactContainer';
import qs from 'query-string'

const PasswordChangePage = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const controller = useForm(form_data)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const {form, onResetForm, onChangeForm, onGetFormData} = controller
    const authcontroller = useAuth()

    const pageParams = qs.parse(location.search)

    useEffect(() => {
        if(form.password.value !== form.passwordConfirmation.value){
            let data2update = {...form.passwordConfirmation}
            data2update.isValid = false
            onChangeForm(data2update)
        }
    }, [form.password.value, form.passwordConfirmation.value])

    const [view, setView] = useState({
        password:false,
        passwordConfirmation:false
    })

    const onChange = (key) => {
        let temp = {...view}
        temp[key] = !temp[key]
        setView(temp)
    }

    const onSubmit = async() => {
        //setError({message:'El correo ya existe'})
        
        console.log(pageParams)
        const data2send = onGetFormData()


        if(data2send.password !== data2send.passwordConfirmation){
            setError({message:'Las contraseñas no coinciden'})
            return
        }

        if(!pageParams?.token){
            setError({message:'No existe un token válido para actualizar la contraseña en la solicitud'})
            return
        }

        setLoading(true)
        try {
            
            await authcontroller.actions.onValidatePasswordResetToken({password_change_token:pageParams?.token})

            const finaldata = {
                password_change_token:pageParams?.token,
                password:data2send.password
            }

            await authcontroller.actions.onUpdatePasswordbyToken(finaldata)
            setSuccess('Contraseña actualizada exitosamente exitosamente')
            onResetForm()
        } catch (error) {
            console.log(error)
            setError(error)
        }
        
        setLoading(false)
    } 

    form.password.config.type = view.password ? 'text' : 'password'
    form.passwordConfirmation.config.type = view.passwordConfirmation ? 'text' : 'password'

    return ( 
        <CompactContainer>
            <Helmet title='Recuperación de contraseña | Velfare Finance'/>
            <Box sx={{position:'fixed', top:16, left:16}}><Logo  size={48} /></Box>
            <div className={classes.root}>
                <Stack>
                    <div>
                        <PasswordIcon sx={{ mb: 5, height: 96 }} />
                    </div>
                    <Typography variant='h3' align='center'>Actualiza tu contraseña</Typography>
                    <Typography sx={{marginTop:2}} color='textSecondary'>
                        Por favor ingresa ingresa tu nueva contraseña para poder usar todos los servicios de Velfare
                    </Typography>
                    <Box sx={{my:2}}>
                        <Stack spacing={2}>
                            <InputForm data={form.password} onChange={onChangeForm} 
                                endAdornment={<ShowPasswordBtn
                                    show={view.password} 
                                    onChange={() => onChange('password')} />
                                }
                            />
                            <InputForm data={form.passwordConfirmation} onChange={onChangeForm} 
                                endAdornment={<ShowPasswordBtn 
                                    show={view.passwordConfirmation} 
                                    onChange={() => onChange('passwordConfirmation')} />
                                }
                            />
                        </Stack>
                    </Box>
                    
                    {error && <Box sx={{marginTop:0, marginBottom:4}}>
                        <ErrorAlert
                        error={error} 
                        onClose={() => setError(null)} />
                    </Box>}
                    {success && <Box sx={{marginTop:0, marginBottom:4}}>
                        <Alert severity='success'  onClose={() => setSuccess(null)}>
                                {success}
                        </Alert>
                    </Box>}
                    <Box sx={{marginTop:0, marginBottom:4}}>
                        <ContainedButton fullWidth /*disabled={!form.password.isValid || !form.passwordConfirmation.isValid}*/ loading={loading}
                        onClick={onSubmit}>Actualizar contraseña</ContainedButton>
                    </Box>
                </Stack>
            </div>
        </CompactContainer>
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        //border:'1px solid black'
    }
}))
 
export default PasswordChangePage;

const form_data = {
    password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        injectData:'passwordConfirmation',
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña',
            autoComplete:"new-password",
            placeholder:'Ingresa tu contraseña',
            helperText:'Debe contener al menos 6 caracteres'
        },
        rules: {type: 'distance', min:6}
    },
    passwordConfirmation: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        compareTarget:'new_password',
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label:'Confirmar contraseña',
            placeholder:'Confirmar contraseña',
            helperText:'Las contraseñas no coinciden'
        },
        rules: {type: 'equals'}
    },
}