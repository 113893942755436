import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import useCategory from "../../../hooks/api/useCategory"
import useProduct from "../../../hooks/api/useProduct"
import useTag from "../../../hooks/api/useTag"
import useForm from "../../../hooks/useForm"
import { catalogs } from "../../../utils/catalogs"
import { CATEGORY_TYPES, TAG_TYPES, USER_STATUSES } from "../../../utils/enums"
import { isValidDocument } from "../../../utils/files"
import { onGetCatalogs } from "../../../utils/forms"

const useEquivalentPage = () => {

    const {idproduct} = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const formManager = useForm(form_data)
    const productManager = useProduct()
    const categoryManager = useCategory()
    const tagManager = useTag()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)


    const [account, setAccount] = useState()
    const [file, setFile] = useState()

    const [locations, setLocations] = useState([])
    const [programs, setPrograms] = useState([])

    
    const {form, onValidateForm, onGetFormData, onInitForm, onUpdateDBErrors} = formManager

    const [equivalents, setEquivalents] = useState([...catalogs.equivalents])
    const [equivalents_smae, setEquivalentsSMAE] = useState([...catalogs.equivalents])

    const [categories, setCategories] = useState([])
    const [product_categories, setProductCategories] = useState([])
    const [original_product_categories, setOriginalProductCategories] = useState([])

    const [tags, setTags] = useState([])
    const [product_tags, setProductTags] = useState([])
    const [original_product_tags, setOriginalProductTags] = useState([])
    const [product_images, setProductImages] = useState([])

    useEffect(() => {
        actions.onInitModule()
    },[idproduct])

    

    const onInitEquivalents = useCallback((origin) => {
        let temp = JSON.parse(JSON.stringify(catalogs.equivalents))
        for(let i=0; i<temp.length; i++){
            let _value = origin[temp[i].key]
            if(typeof(_value) === 'string'){
                _value = _value.replace('.000', '')
                _value = _value.replace('.00', '')
            }
            temp[i].data = _value ?? 0 
        }
        setEquivalents(temp)
    },[catalogs.equivalents, setEquivalents])

    const onUpdateEquivalents = useCallback((data) => {
        let result = [...equivalents]
        const pos = data?.value - 1
        result[pos] = data
        setEquivalents(result)

    }, [equivalents, setEquivalents])

    const onInitEquivalentsSMAE = useCallback((origin) => {
        let temp = JSON.parse(JSON.stringify(catalogs.equivalents_smae))
        for(let i=0; i<temp.length; i++){
            let _value = origin[temp[i].key]
            if(typeof(_value) === 'string'){
                _value = _value.replace('.000', '')
                _value = _value.replace('.00', '')
            }
            temp[i].data = _value ?? 0 
        }
        setEquivalentsSMAE(temp)
    },[catalogs.equivalents_smae, setEquivalentsSMAE])

    const onUpdateEquivalentsSMAE = useCallback((data) => {
        let result = [...equivalents_smae]
        const pos = data?.value - 1
        result[pos] = data
        setEquivalentsSMAE(result)

    }, [equivalents_smae, setEquivalentsSMAE])

    

    const onChangeProductCategories = useCallback((data) => {
        console.log(data)
        setProductCategories(data)
    }, [setProductCategories])

    const onChangeProductTags = useCallback((data) => {
        setProductTags(data)
    }, [setProductTags])

    const onGetProductCategories = useCallback(async() => {
        const _categories = await categoryManager.actions.onGetAll({
            order:0, order_by:JSON.stringify(['name']),
            filter:JSON.stringify({AND:[
                {field:'category_type_id', operator:'=', value:CATEGORY_TYPES.PRODUCT},
            ]})
        })

        let _catCategories = onGetCatalogs( _categories.data, 'id_category', 'name')
        setCategories(_catCategories)

        const _recipeCategories = await productManager.actions.onGetCategories({
            filter:JSON.stringify({AND:[
                {field:'product_id', operator:'=', value:idproduct},
            ]})
        })
        setOriginalProductCategories(_recipeCategories.data)
        let _categoryCat = []
        for(let i=0; i<_recipeCategories.data.length; i++){
            const item = _recipeCategories.data[i]
            _categoryCat.push({
                value:item?.category_id,
                label:item?.category,
                ...item
            })
        }

        setProductCategories(_categoryCat)

    },[categoryManager, setCategories, onGetCatalogs, setProductCategories, setOriginalProductCategories])

    const onGetProductTags = useCallback(async() => {
        const _tags = await tagManager.actions.onGetAll({
            order:0, order_by:JSON.stringify(['name']),
            filter:JSON.stringify({AND:[
                {field:'tag_type_id', operator:'=', value:TAG_TYPES.PRODUCT},
            ]})
        })

        let _catTags = onGetCatalogs( _tags.data, 'id_tag', 'name')
        setTags(_catTags)

        const _recipeTags = await productManager.actions.onGetTags({
            filter:JSON.stringify({AND:[
                {field:'product_id', operator:'=', value:idproduct},
            ]})
        })
        //console.log('Product tags', _recipeTags.data)
        setOriginalProductTags(_recipeTags.data)


        let _recipeTagsCat = []
        for(let i=0; i<_recipeTags.data.length; i++){
            const item = _recipeTags.data[i]
            _recipeTagsCat.push({
                value:item?.tag_id,
                label:item?.tag,
                ...item
            })
        }

        setProductTags(_recipeTagsCat)

    },[categoryManager, setCategories, onGetCatalogs, setOriginalProductTags, setProductTags, idproduct])


    const onGetCategories2Send = useCallback(() => {

        let categories2delete = []
        original_product_categories.forEach(item => {
            const elFind = product_categories.find(el => el.value === item.category_id)
            if(!elFind) categories2delete.push(item)
        })

        let categories2add = []
        product_categories.forEach(item => {
            const elFind = original_product_categories.find(el => el.category_id === item.value)
            if(!elFind) categories2add.push(item)
        })

        return {
            categories2delete,
            categories2add
        }

    }, [original_product_categories, product_categories])

    const onGetTags2Send = useCallback(() => {

        let tags2delete = []
        original_product_tags.forEach(item => {
            const elFind = product_tags.find(el => el.value === item.tag_id)
            if(!elFind) tags2delete.push(item)
        })

        let tags2add = []
        product_tags.forEach(item => {
            const elFind = original_product_tags.find(el => el.tag_id === item.value)
            if(!elFind) tags2add.push(item)
        })

        return {
            tags2delete,
            tags2add
        }

    }, [original_product_tags, product_tags])

    const onGetTotalCalories = useCallback(() => {
        let result = {
            smae:0,
            equivalents:0
        }

        let sum_equivalents = 0
        equivalents.forEach(item => {
            const _value = parseFloat(item?.data)
            sum_equivalents+= ((isNaN(_value) ? 0 : _value) * item.calories)
        })
        let sum_smae = 0
        equivalents_smae.forEach(item => {
            const _value = parseFloat(item?.data)
            sum_smae+= ((isNaN(_value) ? 0 : _value) * item.calories)
        })

        result.smae = sum_smae
        result.equivalents = sum_equivalents

        return result
    }, [equivalents, equivalents_smae])

    


    const actions = {
        onInitModule: async() => {
            setLoading(true)
            let _product 
            

            try {
                
                _product = await productManager.actions.onGet(idproduct)
                //console.log(_product)
                onGetOriginalProductImage()
                setAccount(_product)
                onInitForm(_product)
                onInitEquivalents(_product)
                onInitEquivalentsSMAE(_product)

                await onGetProductCategories()
                await onGetProductTags()

            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRefresh: async() => {
            let _product 
            try {
                
                _product = await productManager.actions.onGet(idproduct)
                onGetOriginalProductImage()
                setAccount(_product)
                onInitForm(_product)
                onInitEquivalents(_product)
                onInitEquivalentsSMAE(_product)

                await onGetProductCategories()
                await onGetProductTags()

            } catch (error) {
                
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
        },
        onAvatarDrop: (acceptedFiles) => {
            if(acceptedFiles.length){
                const _file = acceptedFiles[0]
                const newFile = Object.assign(_file, {
                    preview: URL.createObjectURL(_file),
                });
                //console.log(newFile)
                if(_file){
                    setFile(newFile)
                }
                //setFile(_data[0])
            }
        },
        onInputChange: (_input) => {
            formManager.onChangeForm(_input)
        },
        onSubmit: async() => {
            const valid = onValidateForm(form)
            const idproduct = account?.id_product
            if(!valid){
                enqueueSnackbar('Debe llenar todos los campos solicitados', {variant:"error"})
                return
            }
    
            let data2send = onGetFormData({origin: account, eraseEmptySpaces:true})

            const total_calories =  onGetTotalCalories()

            data2send.calories =  total_calories.equivalents > total_calories.smae ?  total_calories.equivalents :  total_calories.smae

            for(let i=0; i<equivalents.length; i++){
                const temp = equivalents[i]
                data2send[temp.key] = temp.data
            }

            for(let i=0; i<equivalents_smae.length; i++){
                const temp = equivalents_smae[i]
                data2send[temp.key] = temp.data
            }
            //console.log(data2send)
            const {categories2delete, categories2add} = onGetCategories2Send()
            const {tags2delete, tags2add} = onGetTags2Send()
            
            //return
            //return
            setSending(true)

            

            try {
                //console.log('ID product es', idproduct)
                await productManager.actions.onEdit(idproduct, data2send)
                if(file){
                    //await productManager.actions.onUpdatePicture(idproduct, file)
                    // Categories section

                    try {
                        let product_image_id 
                        let selectedProduct = product_images.find(el => el.main === 1)

                        if(!selectedProduct){
                            product_image_id = await productManager.actions.onAddImage({
                                "product_id":idproduct,
                                "number":1,
                                "main":1
                            })
                        }else{
                            product_image_id = selectedProduct?.id_product_image
                        }
                        await productManager.actions.onUpdatePicture(product_image_id, file)
                    } catch (error) {
                        //enqueueSnackbar('No se pudo agregar la imagen solicitada', {variant:"error"})
                    }
                }

                // Categories section
                try {
                    if(categories2delete.length){
                        for(let i=0; i<categories2delete.length; i++){
                            const item = categories2delete[i]
                            await productManager.actions.onDeleteCategory(item?.id_product_category)
                        }
                    }
                } catch (error) {
                    enqueueSnackbar('No se pudieron borrar todas las categorias seleccionadas', {variant:"error"})
                }
                try {
                    if(categories2add.length){
                        for(let i=0; i<categories2add.length; i++){
                            const item = categories2add[i]
                            const data2send = {
                                product_id:idproduct,
                                category_id:item?.value,
                                main:0
                            }
                            await productManager.actions.onAddCategory(data2send)
                        }
                    }
                } catch (error) {
                    enqueueSnackbar('No se pudieron agregar todas las categorias seleccionadas', {variant:"error"})
                }

                // Tags section
                try {
                    if(tags2delete.length){
                        for(let i=0; i<tags2delete.length; i++){
                            const item = tags2delete[i]
                            await productManager.actions.onDeleteTag(item?.id_product_tag)
                        }
                    }
                } catch (error) {
                    enqueueSnackbar('No se pudieron borrar todas las etiquetas seleccionadas', {variant:"error"})
                }
                try {
                    if(tags2add.length){
                        for(let i=0; i<tags2add.length; i++){
                            const item = tags2add[i]
                            const data2send = {
                                product_id:idproduct,
                                tag_id:item?.value,
                                main:0
                            }
                            await productManager.actions.onAddTag(data2send)
                        }
                    }
                } catch (error) {
                    enqueueSnackbar('No se pudieron agregar todas las etiquetas seleccionadas', {variant:"error"})
                }

                await actions.onRefresh()
                enqueueSnackbar('Información actualizada exitosamente', {variant:"success"})

            } catch (error) {
                console.log(error)
                if(error.details) onUpdateDBErrors(error.details)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
                setSending(false)
            }
            setSending(false)
        },
        onAvatarDrop: (acceptedFiles) => {
            if(acceptedFiles.length){
                const _file = acceptedFiles[0]
                const newFile = Object.assign(_file, {
                    preview: URL.createObjectURL(_file),
                });
                //console.log(newFile)
                if(_file){
                    setFile(newFile)
                }
                //setFile(_data[0])
            }
        },
        onUpdateEquivalents,
        onUpdateEquivalentsSMAE,
        onChangeProductCategories,
        onChangeProductTags
    }

    const onGetProductImage = useCallback(async(_data) => {
        try {
            if(isValidDocument(_data?.image)){
                
                const image = await productManager.actions.onGetBlobFile(_data?.image)
                actions.onAvatarDrop([image])
            }
        } catch (error) {
            console.log(error)
        }
    },[productManager, actions])

    const onGetOriginalProductImage = useCallback(async() => {
        let _product_images = []
        _product_images = await productManager.actions.onGetImages({filter:JSON.stringify({AND:[
            {field:'product_id', operator:'=', value:idproduct},
        ]})})
        _product_images = _product_images.data
        console.log('Product images',_product_images)
        const mainProduct = _product_images.find(el => el.main === 1)
        console.log('Product images',mainProduct)
        onGetProductImage(mainProduct)
        setProductImages(_product_images)

    },[onGetProductImage, productManager, idproduct])

    



    const system = {loading, sending, error}
    const data = {file, account, locations, programs, equivalents, equivalents_smae,
        categories, product_categories, tags, product_tags}

    return {
        system,
        actions, 
        data,
        form,
    };
}
 
export default useEquivalentPage;

const form_data = {
    name: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Nombre',
            placeholder:'Nombre',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'distance', min:1, max:255}
    },
    ingredients: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ingredients',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Ingredientes',
            placeholder:'Ingredientes recomendados...',
            helperText:'Debe ser un valor entre 1 a 255 caracteres',
            rows:5,
            multiline:true
        },
        rules: {type: 'distance', min:1, max:1025}
    },
    portion: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'portion',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Tamaño de la porción recomendada',
            placeholder:'Tamaño de la porción recomendada...',
            helperText:'Debe ser un valor entre 1 a 255 caracteres',
        },
        rules: {type: 'distance', min:1, max:1025}
    },
    url: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'URL',
            placeholder:'URL...',
            helperText:'Debe ser un valor entre 1 a 255 caracteres'
        },
        rules: {type: 'link'}
    },
    available_in: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'available_in',
            type: 'text',
            fullWidth: true,
            autoComplete:"new-password",
            label:'De venta en',
            placeholder:'Ingrese los lugares donde este producto se puede encontrar...',
            helperText:'Debe ser un valor entre 1 a 255 caracteres',
            rows:5,
            multiline:true
        },
        rules: {type: 'distance', min:1, max:1025}
    },
    // SMAE
    equivalents_active: {
        value:0,
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'equivalents_active',
            type: 'checkbox',
            fullWidth: true,
            autoComplete:"new-password",
            label:'¿Activar equivalentes simplificados?'
        },
        rules: {type: 'none'}
    },
    // SMAE
    smae_active: {
        value:0,
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'smae_active',
            type: 'checkbox',
            fullWidth: true,
            autoComplete:"new-password",
            label:'¿Activar equivalentes SMAE?'
        },
        rules: {type: 'none'}
    },


}
