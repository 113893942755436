import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Stack, Button, TableRow, MenuItem, TableCell, IconButton, Typography, alpha, Box, Tooltip } from '@mui/material';
// components
import Iconify from '../../../../../components/iconify';
import MenuPopover from '../../../../../components/menu-popover';
import ConfirmDialog from '../../../../../components/confirm-dialog';
import ContainedButton from '../../../../../components/actions/ContainedButton';
import { onGetDateWithTimezone } from '../../../../../utils/formatTime';
import { catalogs } from '../../../../../utils/catalogs';
import { blueGrey } from '@mui/material/colors';
import { isValidDocument } from '../../../../../utils/files';

// ----------------------------------------------------------------------

UserPolicyRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onUploadFile: PropTypes.func
};

export default function UserPolicyRow({ 
  row, 
  selected,
   onEditRow, 
   onSelectRow, 
   onDeleteRow, 
   onUploadFile,
   onGetFile,
   }) {

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);


  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };


  const isXML = isValidDocument(row?.invoice_xml)
  const isPDF = isValidDocument(row?.invoice_pdf)
  const isFIle = isValidDocument(row?.file)

  const _visible_path = catalogs.policies_foder_paths.find(el => el.value === row?.path)

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{cursor:'pointer'}} padding="checkbox" onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {row?.id_user_policy}
            </Typography>
        </TableCell>

        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {row?.name}
            </Typography>
        </TableCell>
        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {_visible_path?.label}
            </Typography>
        </TableCell>
        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {onGetDateWithTimezone(row?.start_date, 'DD MMM YY')}
            </Typography>
        </TableCell>
        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
            <Typography variant="subtitle2" noWrap>
              {onGetDateWithTimezone(row?.end_date, 'DD MMM YY')}
            </Typography>
        </TableCell>

        <TableCell sx={{cursor:'pointer'}} align="left" onClick={onSelectRow}>
          <Tooltip title='Descargar documento'>
            <IconButton disabled={!isFIle} onClick={() => onGetFile(row, `${row?.name}`)}>
              <Iconify icon='bi:file-earmark-pdf-fill' />
            </IconButton>
          </Tooltip>
        </TableCell>




        {/*<TableCell align="left">
          <Label
            variant="soft"
            color={(status === 'banned' && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
  </TableCell>*/}

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" >
        

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
          sx={{ color: blueGrey[600] }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            onUploadFile(row, 'file');
            handleClosePopover();
          }}
          sx={{ color: blueGrey[600] }}
        >
          <Iconify icon='bi:file-earmark-pdf-fill' />
          Subir PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Elimina poliza"
        content="¿Está seguro de eliminar esta póliza? Ya no podrá recuperarse en un futuro"
        action={
          <ContainedButton size='small' onClick={() => {
            handleCloseConfirm();
            onDeleteRow()
          }}>
            Eliminar
          </ContainedButton>
        }
      />
    </>
  );
}
