import React, { useRef, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { ruleValidation } from './customFunctions'
import {withStyles} from '@mui/styles'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const AutocompleteForm = props => {

    const {data, onChange, startAdornment, endAdornment, 
      onKeyUp, onKeyDown, delayedSearch, onDelayedSearch, loading, inputRootStyles, disabled, onKeyPress} = props
    const {config, value, isValid, isVisited, options} = data

    const id = data?.config?.id

    const timerRef = useRef()

    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const onInnerChange = (event, _data) => {
        let temp = {...data};
        temp.value = _data;
        console.log(temp)
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.compareValue);
        temp.isVisited = true;
        console.log(temp)
        onChange(temp);
    } 

    const onStartCountDown = () => {
      if(timerRef.current) clearTimeout(timerRef.current)
      timerRef.current = setTimeout(onRequestSearch, 500);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onRequestSearch = async() => { 

      if(timerRef.current) clearTimeout(timerRef.current) 
      onDelayedSearch(id, inputValue)
        //
        //let temp = {...tableFilter}
        //temp.offset = 0
        //onUpdateTableFilter(temp)            
    }


    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    return(
        <div>
            <Autocomplete
              disabled={disabled}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option?.label === value?.label}
              getOptionLabel={(option) => option?.label ?? ''}
              options={options??[]}
              loading={loading}
              value={value}
              onChange={onInnerChange}
              onInputChange={(_, val) => setInputValue(val)}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  {...interConfig}
                  error={isError}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment:startAdornment ? startAdornment : undefined,
                    //endAdornment:endAdornment ? endAdornment : undefined,
                    endAdornment:endAdornment ? endAdornment : undefined,
                    onKeyUp: delayedSearch ? (onStartCountDown) : (onKeyUp ? onKeyUp : undefined),
                    onKeyDown: delayedSearch ? onStopCountDown : (onKeyDown ? onKeyDown : undefined),
                    onKeyPress:onKeyPress,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  sx={{
                    
                  }}
                />
              )}
            />

        </div>
    )
}

export default AutocompleteForm