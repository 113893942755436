import { Grid, TextField, Typography, alpha, Box, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { catalogs } from '../../../utils/catalogs';
import SVGIcon from '../../images/SVGIcon';


const EquivalentsSMAEForm = ({
    equivalents=[],
    onChange
}) => {

    const [total, setTotal] = useState(0)

    useEffect(() => {
        let result = 0
        equivalents.forEach(item => {
            const _value = parseFloat(item?.data)
            result+= ((isNaN(_value) ? 0 : _value) * item.calories)
        })
        setTotal(result)
    }, [equivalents])

    const onInnerChange = (t,e) => {
        if(e.target.value === '.'){
            t.data = e.target.value
            onChange(t)
        }else if(e.target.value?.includes('.')){
            t.data = e.target.value
            onChange(t)
        }else{
            const _value = parseFloat(e.target.value)
            if(_value >=0 || isNaN(_value)){
                t.data = isNaN(_value) ? '' : _value
                onChange(t)
            }
        }
    }


    return ( 
        <>
            <Typography variant='subtitle1'>Tabla de equivalente SMAE</Typography>
            <Box>
                <Stack spacing={0}>
                    {equivalents.map((item, key) => {
                        return(
                            <Box
                                sx={{
                                    background:alpha(item?.color, 0.2),
                                    px:2, py:1,
                                    borderTopLeftRadius: key === 0 ? 12 : 0,
                                    borderTopRightRadius: key === 0 ? 12 : 0,
                                    borderBottomLeftRadius: key === equivalents.length-1 ? 12 : 0,
                                    borderBottomRightRadius: key === equivalents.length-1 ? 12 : 0
                                }}
                            >
                                <Grid container alignItems='center'>
                                    <Grid item xs={6}>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <SVGIcon src={item?.icon} size={24} color={item?.color}/>
                                            <Typography variant='subtitle2' color={item?.color} >{item?.label}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='subtitle2' color={item?.color} >{item?.sublabel??''}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField 
                                            variant='outlined'
                                            size='small'
                                            value={item?.data}
                                            inputProps={{
                                                style:{textAlign: 'center'}
                                            }}
                                            onChange={(e) => onInnerChange(item,e)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    })}
                    <Box sx={{px:2, py:1}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography variant='subtitle1'>Calorías totales</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant='subtitle1' align='center'>{total} Cal</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    
                </Stack>
            </Box>
        </>
     );
}
 
export default EquivalentsSMAEForm;