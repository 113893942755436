import { CONFIG_SETTINGS } from '../../config';
import { generic_server, private_server, public_server } from '../../utils/axios';
import { USER_TYPES } from '../../utils/enums';
import { fileTypeByUrl } from '../../utils/files';
import { onGetUserTypeFromToken } from '../../utils/general';


const useAppointment = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {

            let result = {
                data:[], 
                count:0
            }
            let request
            request = await private_server.get('/appointment/all', {params:params})
            result.data = request.data.data?.appointments??[]
            result.count = request.data.data.count
            return result
        },
        onGetMeAll: async(params) => {

            let result = {
                data:[], 
                count:0
            }
            let request
            request = await private_server.get('/appointment/me/all/nutritionist', {params:params})
            result.data = request.data.data?.appointments??[]
            result.count = request.data.data.count
            return result
        },
        onGetAllByNutritionist: async(userid, params) => {

            let result = {
                data:[], 
                count:0
            }
            let request
            request = await private_server.get(`/appointment/${userid}/all/nutritionist`, {params:params})
            result.data = request.data.data?.appointments??[]
            result.count = request.data.data.count
            return result
        },
        onGetPublic: async(idappointment) => {
            let result = null
            let request =  await public_server.get(`/appointment/${idappointment}/link`)
            result = request.data.data.appointment
            return result
        },
        onGet: async(idappointment) => {
            let result = null
            const userType = onGetUserTypeFromToken()
            let request
            if(userType === USER_TYPES.ADMIN){
                request = await private_server.get(`/appointment/${idappointment}`)
            }else{
                request = await private_server.get(`/appointment/${idappointment}/nutritionist`)
            }
            result = request.data.data.appointment
            return result
        },
        onGetMe: async() => {
            let result = null
            const request = await private_server.get(`/appointment/me`)
            result = request.data.data.appointment
            return result
        },
        onSchedule: async(data2send) =>{
            let result = null
            const userType = onGetUserTypeFromToken()
            let request
            if(userType === USER_TYPES.ADMIN){
                request = await private_server.post('/appointment/schedule', data2send)
            }else{
                request = await private_server.post('/appointment/schedule/nutritionist', data2send)
            }
            result = request.data.data?.appointment?.id_appointment
            return result
        },
        onReschedule: async(appointmentid, data2send) =>{
            const userType = onGetUserTypeFromToken()
            let request
            if(userType === USER_TYPES.ADMIN){
                request = await private_server.post(`/appointment/${appointmentid}/reschedule`, data2send)
            }else{
                request = await private_server.post(`/appointment/${appointmentid}/reschedule/nutritionist`, data2send)
            }
            return request?.data?.data
        },
        onCompleted: async(idappointment,data2send) =>{
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                await private_server.post(`/appointment/${idappointment}/complete`, data2send)
            }else{
                await private_server.post(`/appointment/${idappointment}/complete/nutritionist`, data2send)
            }
           
        },
        onCancel: async(idappointment) =>{
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                await private_server.post(`/appointment/${idappointment}/cancel`)
            }else{
                await private_server.post(`/appointment/${idappointment}/cancel/nutritionist`)
            }
            
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/appointment', data2send)
            result = request.data.data?.appointment?.id_appointment
            return result
        },
        onEdit: async(idappointment, data2send) => {
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                await private_server.patch(`/appointment/${idappointment}`, data2send)
            }else{
                await private_server.patch(`/appointment/${idappointment}/nutritionist`, data2send)
            }
        },
        onEnable: async(idappointment) => {
            const userType = onGetUserTypeFromToken()
            if(userType === USER_TYPES.ADMIN){
                await private_server.post(`/appointment/${idappointment}/enable`)
            }else{
                await private_server.post(`/appointment/${idappointment}/enable/nutritionist`)
            }
            
        },
        onDisable: async(idappointment) => {
            await private_server.post(`/appointment/${idappointment}/disable`)
        },
        onDelete: async(id) => {
            await private_server.delete(`/appointment/${id}`)
        },

    }

    return {
        actions
    }
}
 
export default useAppointment;