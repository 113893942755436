import { Box, CircularProgress, IconButton, Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { pdf, usePDF } from '@react-pdf/renderer';
//import DietPDF from './DietPDF';
import FileSaver from 'file-saver';
import Iconify from '../../../components/iconify';
import OverviewPDF from './OverviewPDF';


const OverviewPDFButton = ({
    fullname, appointments, metrics, busy
}) => {

    if(busy) return (
        <Box>
            <CircularProgress size={40} />
        </Box>
    )

    const onClick = async() => {


        try {
            const myDoc = await <OverviewPDF fullname={fullname} appointments={appointments} metrics={metrics} />
            const blob = await pdf(myDoc).toBlob();
            FileSaver.saveAs(blob, `consultas-${fullname}.pdf`);

        } catch (error) {
            
        }
    }
    
    return ( 
        <>
        
            <IconButton
                //disabled={instance.error}
                onClick={onClick}
                //href={instance.url}
                download={`plan-${`${fullname}`.trim()}.pdf`}
                sx={{
                    background: theme => theme.palette.primary.main,
                    '&:hover':{
                        background: theme => theme.palette.primary.dark,

                    }
                }}
            >
                <Iconify icon='fluent:print-20-filled' color="white"/>
            </IconButton>
            {/*<Modal open={true}>
                <>
                    {instance && 
                        <Box sx={{
                            width:1200,
                            background:'white',
                            height:800,
                            margin:'auto',
                            mt:5,
                        }}>
                            <iframe src={instance.url} width='100%' height='100%' />
                        </Box>
                    
                    }
                </>
                </Modal>*/}
        </>
        
     );
}
 
export default OverviewPDFButton;